import React, {useEffect, useState} from 'react';
import Helmet from 'react-helmet';
import find from 'lodash/find';

export default ({zendeskId, routeConfig = [], location = {}}) => {
	const [ready, setReady] = useState(false);

	// Check for zendesk
	useEffect(() => {
		const readyInterval = setInterval(() => {
			if (window && window.zE) {
				setReady(true);
				clearInterval(readyInterval);
			}
		}, 500);

		return () => clearInterval(readyInterval);
	}, []);

	useEffect(() => {
		if (typeof window === 'undefined' || !ready) return;

		const route = find(routeConfig, {pathName: location.pathname});

		if (!route) return;

		if (route.disable) {
			window.zE('webWidget', 'hide');
		} else if (route.delay) {
			window.zE('webWidget', 'hide');

			setTimeout(() => {
				window.zE('webWidget', 'show');
			}, route.delay * 1000);
		} else {
			window.zE('webWidgezt', 'show');
		}
	}, [ready, location]);

	return (
		<Helmet>
			<script
				id="ze-snippet"
				src={`https://static.zdassets.com/ekr/snippet.js?key=${zendeskId}`}
			/>
		</Helmet>
	);
};
