import React from 'react';
import {Link} from 'gatsby';
import {LocaleConsumer} from './LocaleProvider';

const SmartLink = ({
	_type,
	title,
	url,
	product,
	slug,
	openInNewWindow,
	productName,
	pageName,
	collectionName,
	children,
	locale = {},
	...props
}) => {
	let prefix = '';

	if (locale.id) {
		prefix = locale.isDefault ? '' : `/${locale.id}`;
	}

	if (_type === 'collectionLink') {
		return (
			<Link
				{...props}
				title={title || productName}
				aria-label={title || productName}
				target={openInNewWindow ? '_blank' : null}
				to={`${prefix}/collections/${slug}`}>
				{children || title || collectionName}
			</Link>
		);
	} else if (_type === 'productLink') {
		return (
			<Link
				{...props}
				title={title || productName}
				aria-label={title || productName}
				target={openInNewWindow ? '_blank' : null}
				to={`${prefix}/products/${slug}`}>
				{children || title || productName}
			</Link>
		);
	} else if (_type === 'pageLink') {
		return (
			<Link
				{...props}
				title={title || pageName}
				aria-label={title || pageName}
				target={openInNewWindow ? '_blank' : null}
				rel={openInNewWindow ? 'noopener noreferrer' : null}
				to={`${prefix}${url}`}>
				{children || title || pageName}
			</Link>
		);
	} else if (url && url[0] === '/') {
		return (
			<Link
				{...props}
				title={title}
				aria-label={title}
				target={openInNewWindow ? '_blank' : null}
				rel={openInNewWindow ? 'noopener noreferrer' : null}
				to={`${prefix}${url}`}>
				{children || title}
			</Link>
		);
	} else {
		return (
			<a
				{...props}
				title={title}
				aria-label={title}
				target={openInNewWindow ? '_blank' : null}
				rel={openInNewWindow ? 'noopener noreferrer' : null}
				href={url}>
				{children || title}
			</a>
		);
	}
};

const LinkWrapper = props => (
	<LocaleConsumer>
		{locale => {
			return <SmartLink {...props} locale={locale} />;
		}}
	</LocaleConsumer>
);

export default LinkWrapper;
