import React, {useState, useEffect} from 'react';
import classNames from 'classnames';

export default ({
	initialValue = '',
	formClassName,
	onSubmit,
	className,
	submitCta,
	...props
}) => {
	const [value, setValue] = useState(initialValue);

	useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	const onSubmitForm = event => {
		event.preventDefault();
		onSubmit(value);
	};

	return (
		<form
			title="New bundle" 
			aria-label="New bundle"
			onSubmit={onSubmitForm}
			className={classNames(formClassName, 'db x pr')}>
			<input
				{...props}
				value={value}
				onChange={event => setValue(event.target.value)}
				className={classNames('db x input--primary', className)}
			/>
			<button title="Search" aria-label="Search"  className="db pa top right bottom p1 pr2 fw--800">
				{submitCta || 'Submit'}
			</button>
		</form>
	);
};
