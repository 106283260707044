import React, {Component} from 'react';
import {connectToStore} from './Provider';

const INITIAL_STATE = {
	password: '',
};

class PasswordScreen extends Component {
	constructor() {
		super();

		this.state = {...INITIAL_STATE};
	}

	onSubmitPasswordForm = event => {
		event.preventDefault();

		if (this.state.password === this.props.password) {
			this.props.completePasswordCheck();
		}
	};

	render() {
		const {password} = this.state;

		return (
			<form title="Remove media list" aria-label="Remove media list" onSubmit={this.onSubmitPasswordForm} className="x p2">
				<div className="df fdr">
					<div className="fg1">
						<input
							name="password"
							value={password}
							onChange={event =>
								this.setState({password: event.target.value})
							}
							className="x sans--md sans--xl--lg"
							placeholder="password"
						/>
					</div>
					<div className="fg0">
						<button
							title="Submit"
							aria-label="Submit" 
							type="submit"
							name="submit"
							className="x sans--md sans--xl--lg">
							submit
						</button>
					</div>
				</div>
			</form>
		);
	}
}

const mapStateToProps = () => ({});

const mapStoreToProps = ({completePasswordCheck}) => ({
	completePasswordCheck,
});

export default connectToStore(mapStateToProps, mapStoreToProps)(PasswordScreen);
