import React, {useState} from 'react';
import classNames from 'classnames';
import {Link} from 'gatsby';
import centsToPriceNoTrailingZeros from 'magic-tricks/lib/centsToPriceNoTrailingZeros';

export default ({image = {url: ''}, title, cents, slug, onAdd, isAdding}) => {
	const [isAdded, setIsAdded] = useState(false);

	const onClickAdd = () => {
		onAdd().then(() => {
			setIsAdded(true);
		});
	};

	return (
		<div>
			<Link to={`/products/${slug}`} className="db link--opacity mb1" aria-label={title}>
				<div className="image size--3x4">
					<picture>
						<img
							alt={title}
							src={`${image.url}?w=225&h=300&auto=format`}
						/>
					</picture>
				</div>
			</Link>
			<Link
				className="sans--xs link--opacity db mb2"
				to={`/products/${slug}`} aria-label={title}>
				<p className="ellipsis">{title}</p>
				<p>{centsToPriceNoTrailingZeros(cents)}</p>
			</Link>
			<button
				title={isAdded ? 'Added' : 'Add'}
				aria-label={isAdded ? 'Added' : 'Add'}
				onClick={onClickAdd}
				disabled={isAdding}
				className={classNames('button--pill--primary invert', {
					loading: isAdding,
				})}>
				{isAdded ? 'Added' : 'Add'}
			</button>
		</div>
	);
};
