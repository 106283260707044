import * as account from '../../api/account';

const cancelSubscription = store => ({subscriptionId, cancellationReason}) => {
	const {
		shopifyAccessToken,
		rechargeAccessToken,
		subscriptions: currentSubscriptions,
	} = store.getState();

	store.setState({
		isFetchingSubscriptions: true,
	});

	if (!shopifyAccessToken || !rechargeAccessToken) return;

	return account
		.cancelSubscription({
			rechargeAccessToken,
			subscriptionId,
			cancellationReason,
		})
		.then(res => {
			if (!res.ok) {
				throw res;
			} else {
				return res.json();
			}
		})
		.then(({subscription}) => {
			const updatedSubscriptions = currentSubscriptions.map(sub =>
				sub.id === subscription.id ? subscription : sub,
			);

			store.setState({
				subscriptions: updatedSubscriptions,
				isFetchingSubscriptions: false,
			});
		})
		.catch(error => {
			store.setState({
				isFetchingSubscriptions: false,
			});
		});
};

export default cancelSubscription;
