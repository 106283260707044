import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import centsToPriceNoTrailingZeros from 'magic-tricks/lib/centsToPriceNoTrailingZeros';
import {Link} from 'gatsby';
import find from 'lodash/find';
import filter from 'lodash/filter';
import Radio from './Radio';
import reduce from 'lodash/reduce';
import pluralize from 'magic-tricks/lib/pluralize';
import getApp from 'magic-tricks/lib/getApp';
import Select from './Select';
import {encode} from '../utils/shopify-gid';

export default ({
	id,
	url,
	title,
	options,
	quantity,
	discounts = [],
	image,
	cents,
	customAttributes = [],
	isUpdatingCheckout,
	isSubscriptionActive,
	updateLineItem,
	productId,
	sku,
	variantTitle,
	variants,
	product,
	addItemAndOpenCart,
	removeLineItem,
	lineItems,
	switchFreePurchaseItem,
	cartTotal,
	resetUserInteraction,
}) => {
	const subscriptionId = find(customAttributes, {
		key: 'subscription_id',
	});
	let subscriptionOptions = find(customAttributes, {
		key: '_subscription_options',
	});
	const activeSubscriptionInterval = find(customAttributes, {
		key: 'shipping_interval_frequency',
	});

	const isSubscription = !!subscriptionId;
	if (subscriptionOptions) {
		try {
			subscriptionOptions = JSON.parse(subscriptionOptions.value);
		} catch (error) {
			console.log(error);
		}
	}

	let activeCents = cents;

	// Create State Objects to manage Free Prouct display
	const [freeBandaid, setFreeBandaid] = useState(true);
	const [freeThermometer, setFreeThermometer] = useState(false);
	const [disabled, setDisabled] = useState('disabled');

	// Set Active Radio State
	useEffect(() => {
		if(variantTitle === "Genexa Thermometer") {
			setFreeThermometer(true);
			setFreeBandaid(false);
		}
		if(variantTitle === "Genexa Bandage Set") {
			setFreeBandaid(true);
			setFreeThermometer(false);
		}

		if(cartTotal >= 6 ) {
			setDisabled('')
		}
	}, []);

	if (discounts.length > 0) {
		activeCents = reduce(
			discounts,
			(activeCents, discount) => {
				const nextDiscountAmount =
					(Number(discount.allocatedAmount.amount) * 100) / quantity;

				return activeCents - nextDiscountAmount;
			},
			cents,
		);
	}

	const onToggleSubscription = () => {
		if (!isSubscription) {
			// Add first option
			// Remove graph model
			updateLineItem([
				{
					id,
					customAttributes: [
						...customAttributes.map(({key, value}) => ({
							key,
							value,
						})),
						{
							key: 'subscription_id',
							value: subscriptionOptions._id,
						},
						{
							key: 'shipping_interval_frequency',
							value: subscriptionOptions.orderIntervalFrequencyOptions[0].toString(),
						},
						{
							key: 'shipping_interval_unit_type',
							value: subscriptionOptions.orderIntervalUnit,
						},
					],
				},
			]);
		} else {
			// Filter out subscription options
			// Remove graph model
			updateLineItem([
				{
					id,
					customAttributes: filter(customAttributes, attribute => {
						return (
							[
								'subscription_id',
								'shipping_interval_frequency',
								'shipping_interval_unit_type',
							].indexOf(attribute.key) === -1
						);
					}).map(({key, value}) => ({
						key,
						value,
					})),
				},
			]);
		}
	};

	const onToggleVaraint = () => {
		<div>
		<Radio
					small
					onClick={onToggleSubscription}
					className="fg0"
					active={isSubscription}
				/>
				<div
					onClick={onToggleSubscription}
					className="fg1 rich-text sans--xs pl1">
					{variantTitle}
				</div>
		</div>
	};

	const onChangeFrequency = frequency => {
		// Filter out frequency,
		// Remove graph model
		// Add frequency
		updateLineItem([
			{
				id,
				customAttributes: [
					...filter(customAttributes, attribute => {
						return (
							['shipping_interval_frequency'].indexOf(
								attribute.key,
							) === -1
						);
					}).map(({key, value}) => ({
						key,
						value,
					})),
					{
						key: 'shipping_interval_frequency',
						value: frequency,
					},
				],
			},
		]);
	};

	const onRemove = (targetId) => {
		updateLineItem([{id, quantity: 0}]);

		const app = getApp();

		if (app.analytics) {
			app.analytics.removeFromCart({
				_productId: productId,
				id: sku,
				name: title,
				brand: 'Genexa',
				category: 'Medication',
				variant: variantTitle,
				price: activeCents / 100,
			});
		}
	};

	let priceSection;

	// If we're not a subscription
	if (!isSubscription) {
		// If there are any subs, don't run shopify discounts
		if (isSubscriptionActive) {
			priceSection = <p>{centsToPriceNoTrailingZeros(cents)}</p>;
			// If no subs, run shopify discounts
		} else {
			priceSection = (
				<p>
					{activeCents < cents && (
						<span className="o5 strike mr1">
							{centsToPriceNoTrailingZeros(cents)}
						</span>
					)}
					{centsToPriceNoTrailingZeros(activeCents)}
				</p>
			);
		}
		// If subscription, discount against original
	} else {
		const subscriptionCents =
			subscriptionOptions.discountType === 'percentage'
				? cents -
				  (Number(subscriptionOptions.discountAmount) / 100) * cents
				: cents - Number(subscriptionOptions.discountAmount) * 100;

		priceSection = (
			<p>
				<span className="o5 strike mr1">
					{centsToPriceNoTrailingZeros(cents)}
				</span>
				{centsToPriceNoTrailingZeros(subscriptionCents)}
			</p>
		);
	}

	const subscriptionFrequencySelect = isSubscription && (
		<Select
			label="Deliver every..."
			value={activeSubscriptionInterval.value}
			triggerClassName="cart-item__frequency-select"
			dropdownAlignment="top cart-item__dropdown"
			optionClassName="sans--xs--md link--opacity"
			options={subscriptionOptions.orderIntervalFrequencyOptions.map(
				option => ({
					title: `Delivery every ${option} ${pluralize(
						subscriptionOptions.orderIntervalUnit,
						option,
					)}`,
					value: option.toString(),
				}),
			)}
			onChange={onChangeFrequency}
		/>
	);

	const quantityForm = (
		<div className="df fdr">
			<button
				aria-label="Remove 1"
				title="minus"
				onClick={() => { updateLineItem([{id, quantity: quantity - 1}]); resetUserInteraction() }}
				disabled={isUpdatingCheckout}
				className={classNames('db link--opacity', {
					loading: isUpdatingCheckout,
				})}>
				<img
					alt="Remove 1"
					src="/images/icon-minus-cart.svg"
					className="db"
				/>
			</button>
			<p className="ml1 mr1">{quantity}</p>
			<button
				aria-label="Add 1"
				title="add"
				onClick={() => { updateLineItem([{id, quantity: quantity + 1}]); resetUserInteraction() }}
				disabled={isUpdatingCheckout}
				className={classNames('db link--opacity', {
					loading: isUpdatingCheckout,
				})}>
				<img
					alt="Remove 1"
					src="/images/icon-plus-cart.svg"
					className="db"
				/>
			</button>
		</div>
	);

	const removeButton = (
		<p className="tr">
			<button
				title="Remove"
				aria-label="Remove"
				onClick={  () => onRemove(id) }
				className={classNames('link--underline-invert-small', {
					'cs--loading': isUpdatingCheckout,
				})}>
				Remove
			</button>
		</p>
	);

	const subscriptionToggle = !!subscriptionOptions && (
		<div className="mt1 mb1">
			<div className="df fdr aic jcc fw--800">
				<Radio
					small
					onClick={onToggleSubscription}
					className="fg0"
					active={isSubscription}
				/>
				<div
					onClick={onToggleSubscription}
					className="fg1 rich-text sans--xs pl1">
					Subscribe + save{' '}
					{subscriptionOptions.discountType === 'percentage'
						? `${subscriptionOptions.discountAmount}%`
						: centsToPriceNoTrailingZeros(
								subscriptionOptions.discountAmount * 100,
						  )}
				</div>
			</div>
		</div>
	);


 // update free purchase item
 const updateFreePurchaseItem = (type) => {

	 // Handled logic at parent
	 switchFreePurchaseItem(type)

	 if(type === "bandaids") {
		 setFreeThermometer(false)
		 setFreeBandaid(true)
	 } else {
		 setFreeThermometer(true)
		 setFreeBandaid(false)
	 }
 }

	return (
		<div className="row cart-item__row">
			<div className="col c6 cart-item__col">
			{ title != 'Free Gift With Purchase' ?
				<Link className="db x link--opacity" aria-label={title} title={title} to={url}>
					<div className="db x image size--4x6">
						<picture>
							<img alt={title} src={image} />
						</picture>
					</div>
				</Link>
			:
				<div className="db x link--opacity">
					<div className="db x image size--4x6">
						<picture>
							<img alt={title} src={image} />
						</picture>
					</div>
				</div>
			}
			</div>
			<div className='col c6 cart-item__col jcb removeFreeProd'>
				<div className="mb2">
					<div className="fw--800">
					{title != 'Free Gift With Purchase' ?
						<Link className="link--opacity" aria-label={title} title={title} to={url}>
							{title}
						</Link>
						:
						<div className="link--opacity">
							{title}
						  <div className="free__item__selection">
								<div className="flex__wrapper">
									<Radio
										small
										onClick={() => updateFreePurchaseItem('bandaids') }
										className="fg0"
										active={freeBandaid}
									/>
									<div
										onClick={() => updateFreePurchaseItem('bandaids')}
										className="fg1 rich-text sans--xs pl1">
									  Genexa Bandage Set
									</div>
								</div>
								{cartTotal >= 6 &&
									<div className="flex__wrapper">
										<Radio
											small
											onClick={() => updateFreePurchaseItem('thermometer') }
											className="fg0"
											active={freeThermometer}
										/>
										<div
											onClick={() => updateFreePurchaseItem('thermometer')}
											className="fg1 rich-text sans--xs pl1">
											Genexa Thermometer Set
										</div>
								 </div>
								}

							</div>
						</div>
					}
					</div>
					{title != 'Free Gift With Purchase' ? priceSection : ''}
					{subscriptionToggle}
					{subscriptionFrequencySelect}
					{title == 'Free Gift With Purchase' ?  onToggleVaraint : ''}
				</div>
				<div className= {title != 'Free Gift With Purchase' ? 'df fdr jcb' : 'df fdr jce'}>
					{title != 'Free Gift With Purchase' ? quantityForm : ''}
					{removeButton}
				</div>
			</div>
		</div>
	);
};
