import find from 'lodash/find';

export default (variants = [], customer) => {
	if (!customer) return variants[0];

	const {tags = []} = customer;

	if (tags.length) {
		for (let i = 0; i < tags.length; i++) {
			const tag = tags[i];

			let currentDiscountVariant = find(
				variants,
				variant => variant.option1.indexOf(tag) > -1,
			);

			if (currentDiscountVariant) {
				return currentDiscountVariant;
			}
		}
	}

	return variants[0];
};
