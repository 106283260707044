import {navigate} from 'gatsby';
import createStore from 'magic-tricks/lib/createStore';
import cookie from 'js-cookie';
import shopify from '../api/shopify';
import * as account from '../api/account';
import hydrateCustomerSubscriptions from '../services/subscription/hydrateCustomerSubscriptions';
import hydrateCustomerDetails from '../services/subscription/hydrateCustomerDetails';
import updateShippingAddress from '../services/subscription/updateShippingAddress';
import updateRechargeCustomer from '../services/subscription/updateRechargeCustomer';
import updateSubscriptionDetails from '../services/subscription/updateSubscriptionDetails';
import skipNextCharge from '../services/subscription/skipNextCharge';
import cancelSubscription from '../services/subscription/cancelSubscription';
import debounce from 'lodash/debounce';
import random from 'lodash/random';
import omit from 'lodash/omit';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import {decode} from '../utils/shopify-gid';
import {createRechargeCheckout} from '../api/recharge';
import queryString from 'query-string';

const CLIENT = typeof window !== 'undefined';

export const CHECKOUT_KEY = 'genexa_checkout';
export const CUSTOMER_KEY = 'genexa_customer';
export const PASSWORD_KEY = 'genexa_password';
export const BANNER_KEY = 'genexa_banner';
export const POPUP_KEY = 'popup_popup';
export const TOKEN_KEY = 'genexa_token';
export const RECHARGE_TOKEN_KEY = 'genexa_recharge_token';
export const STORE_KEY = 'genexa_store';
export const COOKIES_KEY = 'genexa_accept_cookies';

export const STORE_SAVE_THROTTLE = 1000;
export const BUNDLE_LENGTH = 6;
export const BUNDLE_COLORS = [
	'#8296DB',
	'#5ED1D6',
	'#59ABE5',
	'#87D98C',
	'#FC9173',
];

export const CART_IMAGE_OPTIONS = {
	maxWidth: 400,
	maxHeight: 400,
};

export const DEFAULT_STATE = {
	isCartOpen: false,
	isTakeoverOpen: false,
	hasEnteredSitePassword: false,
	hasClosedBanner: false,
	hasClosedPopup: false,
	hasAcceptedCookies: false,

	// Bundles
	savedBundlesById: {
		new: {
			bundleId: 'new',
			title: 'Your Medicine Cabinet',
			productIds: [],
			color: null,
		},
	},

	// Accounts
	customerToken: null,
	rechargeAccessToken: null,
	customer: null,
	rechargeCustomer: null,
	isHydratingCustomer: false,
	isFetchingAccountDetails: false,
	orders: [],
	addresses: [],
	defaultAddress: null,

	// Checkout
	checkoutId: null,
	checkout: null,
	isHydratingCheckout: false,
	isAddingItem: false,
	isUpdatingCheckout: false,
	isNavigatingToCheckout: false,
	isCheckoutEmailUpdated:false,
	isCheckoutDefaultShippingUpdated:false,
};

//
// === Actions ===
//

const acceptCookies = store => () => {
	cookie.set(COOKIES_KEY, true, {expires: 90});

	store.setState({hasAcceptedCookies: true});
};

const hydrateCookiesConsent = store => () => {
	const hasAcceptedCookies = Boolean(cookie.get(COOKIES_KEY));

	store.setState({hasAcceptedCookies});
};

const closeBanner = store => () => {
	cookie.set(BANNER_KEY, 'true', {expires: 7});

	store.setState({hasClosedBanner: true});
};

const hydrateBanner = store => () => {
	const hasClosedBanner = Boolean(cookie.get(BANNER_KEY));

	store.setState({hasClosedBanner});
};

const closePopup = store => () => {
	cookie.set(POPUP_KEY, 'true', {expires: 7});

	store.setState({hasClosedPopup: true});
};

const hydratePopup = store => () => {
	const hasClosedPopup = Boolean(cookie.get(POPUP_KEY));

	store.setState({hasClosedPopup});
};

const completePasswordCheck = store => () => {
	cookie.set(PASSWORD_KEY, 'true', {expires: 7});

	store.setState({hasEnteredSitePassword: true});
};

const hydratePassword = store => () => {
	const hasEnteredSitePassword = Boolean(cookie.get(PASSWORD_KEY));

	store.setState({hasEnteredSitePassword});
};

const hydrateTokenFromCache = store => () => {
	const customerToken = cookie.get(TOKEN_KEY);
	const rechargeAccessToken = cookie.get(RECHARGE_TOKEN_KEY);

	if (!customerToken) return;

	store.setState({
		customerToken,
		rechargeAccessToken,
	});
};

const hydrateTokenFromServer = store => ({
	token: customerToken,
	customer,
	rechargeAccessToken,
	rechargeCustomer,
}) => {
	cookie.set(TOKEN_KEY, customerToken, {expires: 25});
	cookie.set(RECHARGE_TOKEN_KEY, rechargeAccessToken, {expires: 25});

	const {
		customer: existingCustomer,
		rechargeCustomer: existingRechargeCustomer,
	} = store.getState();

	cookie.set(CUSTOMER_KEY, customer?.id || existingCustomer?.id, {expires: 25});

	store.setState({
		customerToken,
		customer: customer || existingCustomer,
		rechargeAccessToken,
		rechargeCustomer: rechargeCustomer || existingRechargeCustomer,
	});
};

const hydrateCustomer = store => () => {
	const {customerToken} = store.getState();

	// No token
	if (!customerToken) {
		store.setState({
			isHydratingCustomer: false,
		});
		//npsDelighted();
		return;
	}

	store.setState({
		isHydratingCustomer: true,
	});

	return account
		.getCustomer(customerToken)
		.then(res => {
			if (!res.ok) {
				throw res;
			} else {
				return res.json();
			}
		})
		.then(res => {
			// Hydrate customer
			store.setState({
				customer: res,
				isHydratingCustomer: false,
			});
			npsDelighted(res);
		})
		.catch(error => {
			store.setState({
				isHydratingCustomer: false,
			});
		});
};

const hydrateCustomerAccount = store => () => {
	const {customerToken} = store.getState();

	if (!customerToken) return;

	store.setState({isFetchingAccountDetails: true});

	return account
		.getCustomerAccount(customerToken)
		.then(res => {
			if (!res.ok) {
				throw res;
			} else {
				return res.json();
			}
		})
		.then(({orders, addresses, defaultAddress}) => {
			store.setState({
				orders,
				addresses,
				defaultAddress,
				isFetchingAccountDetails: false,
			});
		})
		.catch(error => {
			store.setState({
				isFetchingAccountDetails: false,
			});
		});
};

const logout = store => () => {
	const {customerToken} = store.getState();

	if (!customerToken) return;

	return account
		.logout(customerToken)
		.then(res => {
			if (!res.ok) {
				throw res;
			} else {
				return res.json();
			}
		})
		.then(({token}) => {
			// Hydrate customer
			store.setState({
				token: null,
				customer: null,
				checkout:null,
				checkoutId:null,
			});

			cookie.remove(CUSTOMER_KEY);
			cookie.remove(TOKEN_KEY);
			cookie.remove(RECHARGE_TOKEN_KEY);
			hydrateCheckout(store)();
			//npsDelighted();
			// Navigate to login
			navigate('/account/login', {
				replace: true,
			});
		})
		.catch(error => {
			navigate('/account/login', {
				replace: true,
			});
		});
};

const openCart = store => () => {
	store.setState({
		isCartOpen: true,
	});
};

const closeCart = store => () => {
	store.setState({
		isCartOpen: false,
	});
};

const openTakeover = store => () => {
	store.setState({
		isTakeoverOpen: true,
	});
};

const closeTakeover = store => () => {
	store.setState({
		isTakeoverOpen: false,
	});
};

const applyDiscountCode = store => async () => {
	const {id} = store.getState().checkout;
	const query = queryString.parse(window.location.search);

	if (query.discount) {
		console.log(shopify.checkout, query);
		await shopify.checkout.addDiscount(id, query.discount);
	}
};

const applyEverflow = store => async () => {
	const {EF} = window;

	if (!EF) return;

	EF.click({
		offer_id: EF.urlParameter('oid'),
		affiliate_id: EF.urlParameter('affid'),
		transaction_id: EF.urlParameter('_ef_transaction_id'),
		sub1: EF.urlParameter('sub1'),
		sub2: EF.urlParameter('sub2'),
		sub3: EF.urlParameter('sub3'),
		sub4: EF.urlParameter('sub4'),
		sub5: EF.urlParameter('sub5'),
		uid: EF.urlParameter('uid'),
		source_id: EF.urlParameter('source_id'),
		creative_id: EF.urlParameter('creative_id'),
	}).then(function (transaction_id) {
		updateCheckoutAttributes(store)([
			{key: 'eftid', value: transaction_id},
		]);
	});
};

const updateCheckoutAttributes = store => async (customAttributes = []) => {
	const {id} = store.getState().checkout;

	return await shopify.checkout.updateAttributes(id, {customAttributes});
};

const createCheckout = store => () => {

	const {customer,isCheckoutEmailUpdated,isCheckoutDefaultShippingUpdated} = store.getState();
	let checkoutInput={allowPartialAddresses:true}

	if(customer){
		if(customer.email && !isCheckoutEmailUpdated){
			checkoutInput.email=customer.email;
			store.setState({isCheckoutEmailUpdated: true});
		}
		if(customer.defaultAddress && !isCheckoutDefaultShippingUpdated){
			checkoutInput.shippingAddress={
				address1: customer.defaultAddress?.address1 || '',
				address2:customer.defaultAddress?.address2 || '',
				city:customer.defaultAddress?.city || '',
				company: customer.defaultAddress?.company || '',
				country: customer.defaultAddress?.country || '',
				firstName:customer.defaultAddress?.firstName || '',
				lastName: customer.defaultAddress?.lastName || '',
				phone: customer.defaultAddress?.phone || '',
				province:customer.defaultAddress?.province || '',
				zip: customer.defaultAddress?.zip || ''
			};
			store.setState({isCheckoutDefaultShippingUpdated: true});
		}

	}
	shopify.checkout.create(checkoutInput).then(checkout => {
		generateCheckoutCookie(checkout.id);
		store.setState({
			isHydratingCheckout: false,
			checkoutId: checkout.id,
			checkout,
		});

		applyDiscountCode(store)();
		applyEverflow(store)();
	});
};

const fetchCheckout = store => () => {
	const create = createCheckout(store);
	const {checkoutId} = store.getState();

	shopify.checkout.fetch(checkoutId).then(checkout => {
		if (checkout && !checkout.completedAt) {
			checkout.lineItems.forEach((item, i) => {
				if (item.variant.image) {
					checkout.lineItems[
						i
					].smallImage = shopify.image.helpers.imageForSize(
						item.variant.image,
						CART_IMAGE_OPTIONS,
					);
				} else {
					checkout.lineItems[i].smallImage = null;
				}
			});
		}

		if (checkout) {
			if (checkout.completedAt) {
				create();
			} else {
				generateCheckoutCookie(checkout.id);

				store.setState({
					isHydratingCheckout: false,
					checkoutId: checkout.id,
					checkout,
				});

				applyDiscountCode(store)();
				applyEverflow(store)();
			}
		} else {
			create();
		}
	});
};

const hydrateCheckout = store => () => {
	const create = createCheckout(store);
	const fetch = fetchCheckout(store);
	const checkoutId = getCheckoutId();
	store.setState({isHydratingCheckout: true});

	if (checkoutId) {
		store.setState({checkoutId});
		fetch();
	} else {
		return create();
	}
};

const addItemAndOpenCart = store => (items = []) => {
	const {checkoutId} = store.getState();
	store.setState({isAddingItem: true});

	return shopify.checkout.addLineItems(checkoutId, items).then(checkout => {
		checkout.lineItems.forEach((item, i) => {
			if (item.variant.image) {
				checkout.lineItems[
					i
				].smallImage = shopify.image.helpers.imageForSize(
					item.variant.image,
					CART_IMAGE_OPTIONS,
				);
			} else {
				checkout.lineItems[i].smallImage = null;
			}
		});

		generateCheckoutCookie(checkout.id);

		store.setState({
			checkout,
			checkoutId: checkout.id,
			isAddingItem: false,
			isCartOpen: true,
			isTakeoverOpen: false,
		});
	});
};

const updateLineItem = store => (items = []) => {
	const {checkoutId} = store.getState();

	store.setState({isUpdatingCheckout: true});

	return shopify.checkout
		.updateLineItems(checkoutId, items)
		.then(checkout => {
			checkout.lineItems.forEach((item, i) => {
				if (item.variant.image) {
					checkout.lineItems[
						i
					].smallImage = shopify.image.helpers.imageForSize(
						item.variant.image,
						CART_IMAGE_OPTIONS,
					);
				} else {
					checkout.lineItems[i].smallImage = null;
				}
			});

			generateCheckoutCookie(checkout.id);

			store.setState({
				checkout,
				checkoutId: checkout.id,
				isUpdatingCheckout: false,
			});
		});
};

const removeLineItem = store => (itemIds = []) => {
	const {checkoutId} = store.getState();

	store.setState({isUpdatingCheckout: true});

	return shopify.checkout
		.removeLineItems(checkoutId, itemIds)
		.then(checkout => {
			checkout.lineItems.forEach((item, i) => {
				if (item.variant.image) {
					checkout.lineItems[
						i
					].smallImage = shopify.image.helpers.imageForSize(
						item.variant.image,
						CART_IMAGE_OPTIONS,
					);
				} else {
					checkout.lineItems[i].smallImage = null;
				}
			});

			generateCheckoutCookie(checkout.id);

			store.setState({
				checkout,
				checkoutId: checkout.id,
				isUpdatingCheckout: false,
			});
		});
};

const navigateToCheckout = store => async () => {
	const {checkout,savedBundlesById,customer} = store.getState();
	if (!checkout || !checkout.webUrl) return;

	updateEmailCheckout(store);
	updateShippingAddressCheckout(store);
	store.setState({isNavigatingToCheckout: true});

	if(typeof window !== 'undefined') {
		localStorage.removeItem('landingPage');
	}


	let isRechargeCheckout = false;

	forEach(checkout.lineItems, item => {
		const subscriptionId = find(item.customAttributes, {
			key: 'subscription_id',
		});

		if (subscriptionId) {
			isRechargeCheckout = true;
		}
	});

	let multipass={};
	if (!isRechargeCheckout) {
		store.setState({isCheckoutEmailUpdated: false,isCheckoutDefaultShippingUpdated:false});
		if(customer && checkout && customer.email && checkout.webUrl){
			multipass = await account.createMultipassUrl({
				email:customer.email,
				return_to:checkout.webUrl
			});
		}
		global.location =multipass.url??checkout.webUrl;
		store.setState({
			savedBundlesById: {
				...savedBundlesById,
				new: {
					bundleId: 'new',
					title: 'Your Medicine Cabinet',
					productIds: [],
					color: null,
				},
			},
		});
		return;
	}

	const lineItems = [];

	forEach(checkout.lineItems, item => {
		const subscriptionInterval = find(item.customAttributes, {
			key: 'shipping_interval_frequency',
		});
		const subscriptionUnit = find(item.customAttributes, {
			key: 'shipping_interval_unit_type',
		});

		lineItems.push({
			quantity: item.quantity,
			variantId: decode(item.variant.id).id,
			orderIntervalFrequency: subscriptionInterval
				? Number(subscriptionInterval.value)
				: null,
			orderIntervalUnit: subscriptionUnit ? subscriptionUnit.value : null,
		});
	});

	const rechargeCheckout = await createRechargeCheckout({
		lineItems,
		shopifyCheckoutId: checkout.id,
	});

	// ASSUMPTION: Currently in `create-recharge-checkout.js`, we've got a hardcoded query param at the end,
	// so under these conditions it's safe to assume we can use string concatenation with `&` here to generate
	// a valid URL. In the general case, we'd want to use the browser's `URL` constructor to make sure query
	// params are handled correctly, in case our URL didn't have any query params to begin with.

	store.setState({isCheckoutEmailUpdated: false,isCheckoutDefaultShippingUpdated:false});
	global.location = `${rechargeCheckout.checkoutURL}`;
	store.setState({
		savedBundlesById: {
			...savedBundlesById,
			new: {
				bundleId: 'new',
				title: 'Your Medicine Cabinet',
				productIds: [],
				color: null,
			},
		},
	});
};

//
// === Bundle Builder Methods ===
//

const addProductsToBundle = store => (bundleId, productIds) => {
	const {savedBundlesById = {}} = store.getState();

	const savedBundle = savedBundlesById[bundleId];

	if (!savedBundle) return;

	store.setState({
		savedBundlesById: {
			...savedBundlesById,
			[bundleId]: {
				...savedBundle,
				productIds: [...savedBundle.productIds, ...productIds],
			},
		},
	});
};

const replaceBundleProducts = store => (bundleId, productIds = []) => {
	const {savedBundlesById = {}} = store.getState();

	const savedBundle = savedBundlesById[bundleId];

	if (!savedBundle) return;

	store.setState({
		savedBundlesById: {
			...savedBundlesById,
			[bundleId]: {
				...savedBundle,
				productIds,
			},
		},
	});
};

const removeProductFromBundleByIndex = store => (bundleId, index) => {
	const {savedBundlesById = {}} = store.getState();

	const savedBundle = savedBundlesById[bundleId];

	if (!savedBundle) return;

	const newProductIds = [...savedBundle.productIds];

	newProductIds.splice(index, 1);

	store.setState({
		savedBundlesById: {
			...savedBundlesById,
			[bundleId]: {
				...savedBundle,
				productIds: newProductIds,
			},
		},
	});
};

const updateBundleTitle = store => (bundleId, title) => {
	const {savedBundlesById} = store.getState();

	const savedBundle = savedBundlesById[bundleId];

	if (!savedBundle) return;

	store.setState({
		savedBundlesById: {
			...savedBundlesById,
			[bundleId]: {
				...savedBundle,
				title,
			},
		},
	});
};

const saveBundleForLater = store => (title, productIds = []) => {
	const bundleId = Math.random().toString(36).substring(8);
	const color = BUNDLE_COLORS[random(0, BUNDLE_COLORS.length - 1)];

	const {savedBundlesById} = store.getState();

	store.setState({
		savedBundlesById: {
			...savedBundlesById,
			[bundleId]: {
				bundleId,
				title,
				color,
				productIds,
				savedDate: new Date().getTime(),
			},
		},
	});

	return bundleId;
};

const resetNewBundle = store => () => {
	const {savedBundlesById} = store.getState();

	store.setState({
		savedBundlesById: {
			...savedBundlesById,
			new: {
				bundleId: 'new',
				title: 'Your Medicine Cabinet',
				productIds: [],
				color: null,
			},
		},
	});
};

const deleteBundle = store => bundleId => {
	const {savedBundlesById} = store.getState();

	store.setState({
		savedBundlesById: omit(savedBundlesById, [bundleId]),
	});
};

const generateCheckoutCookie = checkoutId => {
	const customerKey = cookie.get(CUSTOMER_KEY);
	const checkoutToken=customerKey?CHECKOUT_KEY+'_'+customerKey:CHECKOUT_KEY;
	cookie.set(checkoutToken, checkoutId, {expires: 25});
	return checkoutToken;
};

const getCheckoutId =()=> {
	const customerKey = cookie.get(CUSTOMER_KEY);
	const checkoutToken=customerKey?CHECKOUT_KEY+'_'+customerKey:CHECKOUT_KEY;
	return cookie.get(checkoutToken);
};

const updateEmailCheckout = store => {
	const {checkoutId,customer,isCheckoutEmailUpdated} = store.getState();
    if(isCheckoutEmailUpdated || !checkoutId || !customer ) return;
	shopify.checkout.updateEmail(checkoutId, customer.email).then(checkout => {
		store.setState({isCheckoutEmailUpdated: true});
	});
};

const updateShippingAddressCheckout = store => {

	const {checkoutId,customer,isCheckoutDefaultShippingUpdated} = store.getState();
	if(isCheckoutDefaultShippingUpdated || !checkoutId || !customer || !customer.defaultAddress) return;

	const shippingAddress = {
		address1: customer.defaultAddress?.address1 || '',
		address2:customer.defaultAddress?.address2 || '',
		city:customer.defaultAddress?.city || '',
		company: customer.defaultAddress?.company || '',
		country: customer.defaultAddress?.country || '',
		firstName:customer.defaultAddress?.firstName || '',
		lastName: customer.defaultAddress?.lastName || '',
		phone: customer.defaultAddress?.phone || '',
		province:customer.defaultAddress?.province || '',
		zip: customer.defaultAddress?.zip || ''
	};

	shopify.checkout.updateShippingAddress(checkoutId, shippingAddress).then(checkout => {
		store.setState({isCheckoutDefaultShippingUpdated: true});
	});
};

const npsDelighted = (customer={}) => {

	try {
		let fullname=customer.firstName??'';
		delighted.survey({
			email: customer.email??'', // customer email (optional)
			name:fullname +' '+ customer.lastName??'',  // customer name (optional)
		/*	createdAt: "",  // time subscribed (optional)
			properties: {                       // extra context (optional)
				plan: "",
				company: ""
			}*/
		});
	} catch (error) {
        console.log('nps delighted error');
	}
}

//
// === Create Store ===
//

export default (initialState = {}) => {
	//
	// === Hydrate from localStorage ===
	//
	let savedStoreState = {};

	if (CLIENT) {
		try {
			savedStoreState = JSON.parse(localStorage.getItem(STORE_KEY));
		} catch (error) {
			console.log(
				'[INFO]: localStorage is disabled. Cannot save store state.',
			);
			savedStoreState = {};
		}
	}

	//
	// === Store ===
	//

	const store = createStore({
		...DEFAULT_STATE,
		...initialState,
		...savedStoreState,
	});

	//
	// === Side Effects ===
	//

	if (CLIENT) {
		const saveStateToLocalStorage = newState => {
			try {
				localStorage.setItem(
					STORE_KEY,
					JSON.stringify({
						savedBundlesById: newState.savedBundlesById,
					}),
				);
			} catch (error) {
				console.warn(
					'localStorage is disabled. Cannot save store state.',
				);
			}
		};

		store.listen(
			debounce(saveStateToLocalStorage, STORE_SAVE_THROTTLE, {
				leading: true,
			}),
		);
	}

	//
	// === Interface ===
	//

	return {
		// Interface
		getState: store.getState,
		setState: store.setState,
		listen: store.listen,
		unlisten: store.unlisten,

		// Actions
		acceptCookies: acceptCookies(store),
		hydrateCookiesConsent: hydrateCookiesConsent(store),
		hydrateBanner: hydrateBanner(store),
		closeBanner: closeBanner(store),
		hydratePopup: hydratePopup(store),
		closePopup: closePopup(store),
		completePasswordCheck: completePasswordCheck(store),
		hydratePassword: hydratePassword(store),
		hydrateTokenFromCache: hydrateTokenFromCache(store),
		hydrateTokenFromServer: hydrateTokenFromServer(store),
		hydrateCustomer: hydrateCustomer(store),
		hydrateCustomerAccount: hydrateCustomerAccount(store),
		hydrateCustomerSubscriptions: hydrateCustomerSubscriptions(store),
		hydrateCustomerDetails: hydrateCustomerDetails(store),
		updateShippingAddress: updateShippingAddress(store),
		updateRechargeCustomer: updateRechargeCustomer(store),
		updateSubscriptionDetails: updateSubscriptionDetails(store),
		skipNextCharge: skipNextCharge(store),
		cancelSubscription: cancelSubscription(store),
		logout: logout(store),
		hydrateCheckout: hydrateCheckout(store),
		fetchCheckout: fetchCheckout(store),
		createCheckout: createCheckout(store),
		addItemAndOpenCart: addItemAndOpenCart(store),
		updateLineItem: updateLineItem(store),
		removeLineItem: removeLineItem(store),
		updateCheckoutAttributes: updateCheckoutAttributes(store),
		openCart: openCart(store),
		closeCart: closeCart(store),
		openTakeover: openTakeover(store),
		closeTakeover: closeTakeover(store),
		navigateToCheckout: navigateToCheckout(store),
		addProductsToBundle: addProductsToBundle(store),
		replaceBundleProducts: replaceBundleProducts(store),
		removeProductFromBundleByIndex: removeProductFromBundleByIndex(store),
		updateBundleTitle: updateBundleTitle(store),
		saveBundleForLater: saveBundleForLater(store),
		resetNewBundle: resetNewBundle(store),
		deleteBundle: deleteBundle(store),
		npsDelighted,
	};
};
