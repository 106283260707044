import * as account from '../../api/account';

const updateShippingAddress = store => ({addressId, shippingAddress}) => {
	const {customerToken, rechargeAccessToken} = store.getState();

	store.setState({
		isHydratingCustomer: true,
	});

	if (!customerToken || !rechargeAccessToken) return;

	return account
		.updateShippingAddress({
			rechargeAccessToken,
			addressId,
			shippingAddress,
		})
		.then(res => {
			if (!res.ok) {
				throw res;
			} else {
				return res.json();
			}
		})
		.then(({rechargeCustomer}) => {
			store.setState({
				rechargeCustomer,
				isHydratingCustomer: false,
			});
		})
		.catch(error => {
			store.setState({
				isHydratingCustomer: false,
			});
		});
};

export default updateShippingAddress;
