import React, {useState, useEffect} from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import {connectToStore} from './Provider';
import clientSideComponent from '../middleware/clientSideComponent';
import {compose} from 'recompose';
import {Transition} from 'react-transition-group';

const POPUP_TIMEOUT = 4000;
export const MODAL_TRANSITION_DURATION = 600;

const MODAL_TRANSITION_STYLES = {
	default: {
		transition: `opacity ${MODAL_TRANSITION_DURATION}ms ease, transform ${MODAL_TRANSITION_DURATION}ms cubic-bezier(0, 0, 0.25, 1)`,
	},
	entering: {
		opacity: 0,
		transform: 'translateY(20px)',
	},
	entered: {
		opacity: 1,
		transform: 'translateY(0)',
	},
	exiting: {
		opacity: 0,
		transform: 'translateY(20px)',
	},
	exited: {
		opacity: 0,
		transform: 'translateY(20px)',
	},
};

const Popup = ({popup, location, closePopup, hasClosedPopup}) => {
	const [showPopup, setShowPopup] = useState(false);

	const showPopupConditions =
		!hasClosedPopup && showPopup && location.pathname !== '/';

	useEffect(() => {
		setTimeout(() => {
			setShowPopup(true);
		}, POPUP_TIMEOUT);
	}, []);

	return (
		<Transition
			in={showPopupConditions}
			appear
			mountOnEnter
			unmountOnExit
			timeout={{
				enter: 100,
				exit: MODAL_TRANSITION_DURATION,
			}}>
			{status => (
				<div
					className="popup"
					style={{
						...MODAL_TRANSITION_STYLES.default,
						...MODAL_TRANSITION_STYLES[status],
					}}>
					<div className="p1 pr4 rich-text">
						<BlockContent blocks={popup} serializers={richText} />
					</div>
					<div className="pa top right p1 pen">
						<button
							title="Close"
							aria-label="Close" 
							onClick={closePopup}
							className="db link--opacity pea">
							<img
								className="db"
								src="/images/icon-close-filter.svg"
								alt="Close"
							/>
						</button>
					</div>
				</div>
			)}
		</Transition>
	);
};

const mapStateToProps = ({hasClosedPopup}) => ({
	hasClosedPopup,
});

const mapStoreToProps = ({closePopup}) => ({
	closePopup,
});

export default compose(
	clientSideComponent,
	connectToStore(mapStateToProps, mapStoreToProps),
)(Popup);
