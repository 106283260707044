import React from 'react';

export default ({percentage = 0}) => (
	<div className="progress__container of--hidden">
		<div
			className="progress__bar"
			style={{transform: `translateX(-${(1 - percentage) * 100}%)`}}
		/>
	</div>
);
