import React from 'react';
import classNames from 'classnames';
import {compose} from 'recompose';
import {connectToStore} from '../components/Provider';
import clientSideComponent from '../middleware/clientSideComponent';
import find from 'lodash/find';
import pluralize from 'magic-tricks/lib/pluralize';
import {Link} from 'gatsby';
import Drawer from './Drawer';

const BUNDLE_ID = 'new';
const MAX_PRODUCTS = 6;

const BundleReminder = ({
	productOptions = [],
	bundleProductIds = [],
	location,
}) => {
	const bundleProducts = bundleProductIds.map(_id =>
		find(productOptions, {_id}),
	);
	const tileIndexes = Array.apply(null, Array(MAX_PRODUCTS)).map(
		(item, index) => index,
	);

	const drawerTrigger = ({onClick, isOpen}) => (
		<div className="bundle-reminder__label banner tc df fdr aic">
			<p className="fg1">
				<span className="fw--800">
					You have {bundleProductIds.length}{' '}
					{pluralize('product', bundleProductIds.length)} in your
					cabinet.
				</span>{' '}
				<Link
					aria-label="Add more"
					onClick={e => e.stopPropagation()}
					to="/cabinets/new"
					className="link--underline-invert-small">
					Add more.
				</Link>
			</p>

			<button title="Drawer Flip" aria-label="Drawer Flip" onClick={onClick}>
				<img
					className={classNames('drawer__arrow-flip ml1', {
						active: !isOpen,
					})}
					src="/images/icon-arrow-drawer.svg"
					alt="arrow"
				/>
			</button>
		</div>
	);

	const drawerChildren = !!bundleProducts.length && (
		<div className="mt1 row align--center gutter--vertical gutter--small pb2">
			{tileIndexes.map((item, index) => {
				const product = bundleProducts[index];

				let productTile = product ? (
					<div className="image size--3x4 x">
						<picture>
							<img
								alt={product.title}
								src={`${product.bundleTileImage.url}?w=400&h=533&auto=format`}
							/>
						</picture>
					</div>
				) : (
					<div className="pr">
						<div className="image size--3x4 x pr bg--white" />
						<div className="pa fill df fdc jcc aic">
							<img
								className="db"
								src="/images/icon-plus-bundle.svg"
								alt="Add Bundle"
							/>
						</div>
					</div>
				);

				return (
					<div key={index} className="col c4 c2--md c1--lg">
						{productTile}
					</div>
				);
			})}
		</div>
	);

	return (
		<div className="bg--gray-bg">
			<div className="grid-container contained">
				<Drawer renderTrigger={drawerTrigger}>{drawerChildren}</Drawer>
			</div>
		</div>
	);
};

const mapStateToProps = ({savedBundlesById}) => ({
	bundleProductIds: savedBundlesById[BUNDLE_ID].productIds,
});

export default compose(
	clientSideComponent,
	connectToStore(mapStateToProps),
)(BundleReminder);
