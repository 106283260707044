import React from 'react';
import Helmet from 'react-helmet';

// Gtag fallback
function gtag() {
	if (global.gtag) return gtag(arguments);
	global.dataLayer.push(arguments);
}

//
// === Initialize global Analytics ===
//

export const initialize = ({
	googleAnalyticsPropertyId,
	googleLinkerDomains,
}) => {
	// Initialize Gtag
	global.dataLayer = global.dataLayer || [];
	function gtag(){dataLayer.push(arguments);}/*this function is used to send data to the dataLayer, which is then processed by Google Tag Manager or Google Analytics scripts.*/
	gtag('js', new Date());

	return {
		pageview(location) {
		  // This is being handled bt node package now.
		},
		viewCollection(items = []) {
		 // This is being handled bt node package now.
			// gtag('event', 'view_item_list', {items});
		},
		viewProduct(item = {}) {
			dataLayer.push({
			  'event': 'view_item',
	      'ecommerce': {
	        'detail': {
	          'actionField': {'list': 'PDP viewed'},
	          'products': [item]
	         }
	       }
	    });

		},
		// TODO: Clicking variants, not needed yet.
		clickProduct(item = {}) {
			dataLayer.push({
			  'event': 'view_item',
	      'ecommerce': {
	        'detail': {
	          'actionField': {'list': 'PDP viewed'},
	          'products': [item]
	         }
	       }
	    });
		},
		addToCart(item = {}) {
			dataLayer.push({
			  'event': 'add_to_cart',
	      'ecommerce': {
	        'detail': {
	          'actionField': {'list': 'Add To Cart'},
	          'products': [item]
	         }
	       }
	    });
		},
		removeFromCart(item = {}) {
			dataLayer.push({
			  'event': 'remove_from_cart',
	      'ecommerce': {
	        'detail': {
	          'actionField': {'list': 'Remove From Cart'},
	          'products': [item]
	         }
	       }
	    });
		},
	};
};

//
// === Group all script tags here` ===
//

export default ({gtmPropertyId, googleAnalyticsPropertyId}) => (
	<React.Fragment>
		<Helmet>
			{/* Bazaar Voice */}
			<script src="https://apps.bazaarvoice.com/deployments/genexa/main_site/production/en_US/bv.js?shop=genexa-llc.myshopify.com" />

			{/* klaviyo */}
			<script async type="text/javascript" src="//static.klaviyo.com/onsite/js/klaviyo.js?company_id=PPBiz6"></script>

			{/* Curalate */}
			<script>{`
				var CRL8_SITENAME = 'genexa-hw5e4y'; !function(){var e=window.crl8=window.crl8||{},n=!1,i=[];e.ready=function(e){n?e():i.push(e)},e.pixel=e.pixel||function(){e.pixel.q.push(arguments)},e.pixel.q=e.pixel.q||[];var t=window.document,o=t.createElement("script"),c=e.debug||-1!==t.location.search.indexOf("crl8-debug=true")?"js":"min.js";o.async=!0,o.src=t.location.protocol+"//edge.curalate.com/sites/"+CRL8_SITENAME+"/site/latest/site."+c,o.onload=function(){n=!0,i.forEach(function(e){e()})};var r=t.getElementsByTagName("script")[0];r.parentNode.insertBefore(o,r.nextSibling)}();
			`}</script>

			{/* Delighted NPS */}
			<script type="text/javascript">{`
				!function(e,t,r,n){if(!e[n]){for(var a=e[n]=[],i=["survey","reset","config","init","set","get","event","identify","track","page","screen","group","alias"],s=0;s<i.length;s++){var c=i[s];a[c]=a[c]||function(e){return function(){var t=Array.prototype.slice.call(arguments);a.push([e,t])}}(c)}a.SNIPPET_VERSION="1.0.1";var o=t.createElement("script");o.type="text/javascript",o.async=!0,o.src="https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/"+r+"/"+n+".js";var p=t.getElementsByTagName("script")[0];p.parentNode.insertBefore(o,p)}}(window,document,"jKF0SSh0J1ix9U93","delighted");
			`}</script>

			{/* Meta Pixel Code */}
			<script>{`
				!function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}; if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s)}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js'); fbq('init', '578359500654906'); fbq('track', 'PageView');
			`}</script>
			
			{/* End Meta Pixel Code */}

			{/* Tiktok Pixel Code */}
			<script>{`
				!function (w, d, t) { w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)}; ttq.load('CC75MR3C77UFQ06O32V0'); ttq.page(); }(window, document, 'ttq');
			`}</script>

		</Helmet>
		<noscript>{`
				<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=578359500654906&ev=PageView&noscript=1" alt="Facebook">
			`}</noscript>
		{/* Everflow  commented due to malicious injection*/}
		{/*<script
			type="text/javascript"
			src="https://www.hm2ktrp.com/scripts/sdk/everflow.js"
/>*/}
	</React.Fragment>
);
