import React from 'react';

export const Context = React.createContext(null);

export class LocaleProvider extends React.PureComponent {
	render = () => {
		return (
			<Context.Provider value={this.props.pageContext.locale}>
				{this.props.children}
			</Context.Provider>
		);
	};
}

export default LocaleProvider;

export const LocaleConsumer = Context.Consumer;