import * as account from '../../api/account';

const updateCustomer = store => customerData => {
	const {customerToken, rechargeAccessToken} = store.getState();

	store.setState({
		isHydratingCustomer: true,
	});

	if (!customerToken || !rechargeAccessToken) return;

	return account
		.updateRechargeCustomer({
			rechargeAccessToken,
			customerData,
		})
		.then(res => {
			if (!res.ok) {
				throw res;
			} else {
				return res.json();
			}
		})
		.then(({shopifyCustomer, rechargeCustomer}) => {
			if (shopifyCustomer) {
				store.setState({
					shopifyCustomer, // not returned when we update payment method
				});
			}
			store.setState({
				rechargeCustomer,
				isHydratingCustomer: false,
			});
		})
		.catch(error => {
			store.setState({
				isHydratingCustomer: false,
			});
		});
};

export default updateCustomer;
