import React from 'react';
import classNames from 'classnames';

export default ({
	active,
	small,
	inputRef,
	name,
	id,
	value,
	onChange = () => {},
	...props
}) => (
	<div {...props}>
		<input
			ref={inputRef}
			onChange={onChange}
			checked={active}
			name={name}
			id={id}
			value={value}
			type="checkbox"
			title="Agree to the privacy policy"
			aria-label="Agree to the privacy policy"
			className="db radio__control"
		/>
		<span className={classNames('radio', {active, small})} />
	</div>
);
