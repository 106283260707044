import React, {useState, useEffect, useRef} from 'react';
import classNames from 'classnames';
import Reveal from './Reveal';
import CartItem from './CartItem';
import ProgressBar from './ProgressBar';
import ProductTileRelatedCart from './ProductTileRelatedCart';
import {Link} from 'gatsby';
import {Transition} from 'react-transition-group';
import {connectToStore} from './Provider';
import centsToPriceNoTrailingZeros from 'magic-tricks/lib/centsToPriceNoTrailingZeros';
import pluralize from 'magic-tricks/lib/pluralize';
import viewport from 'magic-tricks/lib/viewport';
import find from 'lodash/find';
import reduce from 'lodash/reduce';
import {encode,decode} from '../utils/shopify-gid';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import getRelatedProducts from '../api/getRelatedProducts';

import getWholesaleVariant from '../utils/getWholesaleVariant';
import getApp from 'magic-tricks/lib/getApp';

const TRANSITION_DURATION = 600;

const CART_DEFAULT_STYLE = {
	transition: `transform ${TRANSITION_DURATION}ms cubic-bezier(0, 0, 0.25, 1)`,
	transform: 'translate3d(100%, 0, 0)',
};

const CART_TRANSITION_STYLES = {
	entering: {transform: 'translate3d(100%, 0, 0)'},
	entered: {transform: 'translate3d(0, 0, 0)'},
	exited: {transform: 'translate3d(100%, 0, 0)'},
	exiting: {transform: 'translate3d(100%, 0, 0)'},
};

const UNDERLAY_DEFAULT_STYLE = {
	transition: `opacity ${TRANSITION_DURATION}ms ease-in-out`,
	opacity: 0,
};

const UNDERLAY_TRANSITION_STYLES = {
	entering: {opacity: 0},
	entered: {opacity: 0.1},
	exited: {opacity: 0},
	exiting: {opacity: 0},
};

const Cart = ({
	customer,
	freeShippingMinimumCents,
	bulkDiscountMinimumItems,
	bulkDiscountPercentage,
	medicineCabinetTitle,
	medicineCabinetDescription,
	medicineCabinetCta,
	relatedProductsTitle,
	isCartOpen,
	lineItems = [],
	freeproductOptions = [],
	variants = [],
	freeVaraint = [],
	cartCount = 0,
	emptyState = [],
	subtotalCents = 0,
	discounts = [],
	updatedAt,
	isAddingItem,
	isNavigatingToCheckout,
	isUpdatingCheckout,
	isPerkInvalid,
	freeproduct,
	closeCart = () => {},
	removeLineItem = () => {},
	updateLineItem = () => {},
	navigateToCheckout = () => {},
	addItemAndOpenCart = () => {},
}) => {
	const productIds = lineItems.map(
		item => decode(item.variant.product.id).id,
	);

	const [relatedProducts, setRelatedProducts] = useState([]);
	const [isAdded, setIsAdded] = useState(false);

	const [removeItemPlease, setRemoveItemPlease] = useState(false);


	const isEmpty = cartCount === 0;
	const isBulkDiscount = cartCount >= bulkDiscountMinimumItems;
	const isSubscriptionActive = reduce(
		lineItems,
		(isSubscriptionActive, item, index) => {
			const subscription = find(item.customAttributes, {
				key: 'subscription_id',
			});

			if (subscription) return index + 1;
			return isSubscriptionActive;
		},
		false,
	);

	const isFreeShipping = (subtotalCents >= freeShippingMinimumCents) || isSubscriptionActive;

	const productQty = lineItems.map((item) => {
		const subscription = find(item.customAttributes, {
			key: 'subscription_id',
		});
		if (subscription) return true;
		return item.quantity
	});

	const cartFooterEl = useRef(null);
	const [footerHeight, setFooterHeight] = useState(null);

	// Fetch related products when product ids change
	useEffect(() => {
		if (!productIds || !productIds.length) return;
		try {
			getRelatedProducts(productIds)
				.then(res => res.json())
				.then(products => setRelatedProducts(products));
		} catch (error) {
			// todo sentry
			console.log(error);
		}
	}, [updatedAt]);


	// Reset footer height on layout change
	useEffect(() => {
		const resizeFooter = () => {
			if (!cartFooterEl.current) return;
			setFooterHeight(
				cartFooterEl.current.getBoundingClientRect().height,
			);
		};

		viewport.listen(resizeFooter);
		resizeFooter();

		return () => viewport.unlisten(resizeFooter);
	});

	const emptyCartBody = isEmpty && (
		<div>
			<p className="mb12">
				Free shipping on orders over{' '}
				{centsToPriceNoTrailingZeros(freeShippingMinimumCents)}.
			</p>

			<img className="mxa db mb3" src="/images/logo-cart.svg" alt="Add into Cart" />

			<div className="rich-text tc" onClick={closeCart}>
				<BlockContent blocks={emptyState} serializers={richText} />
			</div>
		</div>
	);

	// Calculate Cart total - minus any free products
	let cartTotal = 0;
	for(var i = 0; i < lineItems.length; i++) {
			if(lineItems[i].title !== 'Free Gift With Purchase') {
				cartTotal+=lineItems[i].quantity;
			}
	}

	// Check if a free product is in the cart
	const isFreeGiftInCart = find(lineItems, {
		title: 'Free Gift With Purchase',
	});

	// Setup some state variables for display objects
	const [bandaidAdded, setBandaidAdded] = useState(false);
	const [thermometerAdded, setThermometerAdded] = useState(false);
	const [removedFromCart, setRemovedFromCart] = useState(false);
	const [userUpdated, setUserUpdated] = useState(false);
	const [emptiedCart, setEmptiedCart] = useState(false);
	const [cartUpdated, setCartUpdated] = useState(false);

	// When we emty the cart - remove any free items automatically.
	if(cartTotal === 0 && !emptiedCart && isFreeGiftInCart) {
		setEmptiedCart(true);
		setUserUpdated(false);
		setBandaidAdded(false);

		 // Remove items if in cart
		 if(!thermometerAdded) {
			 setBandaidAdded(false);
			 updateLineItem([{id:"Z2lkOi8vc2hvcGlmeS9DaGVja291dExpbmVJdGVtLzM5NDc0MDAxMTE3MjY4MD9jaGVja291dD0wZTcxODA5NjM0YjMxMTk0M2E5MTRlOWI1ZTRlZWI0Yw==", quantity: 0}]);
		 }
		 if(thermometerAdded) {
			 setThermometerAdded(false);
			 updateLineItem([{id:"Z2lkOi8vc2hvcGlmeS9DaGVja291dExpbmVJdGVtLzM5NDc0MDAxMTUwMDM2MD9jaGVja291dD0wZTcxODA5NjM0YjMxMTk0M2E5MTRlOWI1ZTRlZWI0Yw==", quantity: 0}]);
		 }

 		//
	}

	// Remove Thermometer if its in the cart and cart < 6
  if( cartTotal < 6 && thermometerAdded && !cartUpdated ) {
		setCartUpdated(true);
		setUserUpdated(false);

		//
		if(!thermometerAdded) {
			setBandaidAdded(false);
			updateLineItem([{id:"Z2lkOi8vc2hvcGlmeS9DaGVja291dExpbmVJdGVtLzM5NDc0MDAxMTE3MjY4MD9jaGVja291dD0wZTcxODA5NjM0YjMxMTk0M2E5MTRlOWI1ZTRlZWI0Yw==", quantity: 0}]);
		}
		if(thermometerAdded) {
			setThermometerAdded(false);
			updateLineItem([{id:"Z2lkOi8vc2hvcGlmeS9DaGVja291dExpbmVJdGVtLzM5NDc0MDAxMTUwMDM2MD9jaGVja291dD0wZTcxODA5NjM0YjMxMTk0M2E5MTRlOWI1ZTRlZWI0Yw==", quantity: 0}]);
		}
	}

	// If cart is greater than 3 > add the first free product object
	if(cartTotal > 3 && !bandaidAdded && !isFreeGiftInCart && !userUpdated && !thermometerAdded ){
		setBandaidAdded(true);
		setRemovedFromCart(false);
		setEmptiedCart(false);

    // Add Bandaid to cart
		addItemAndOpenCart([
			{
				quantity: 1,
				variantId: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTQ3NDAwMTExNzI2OD9hY2Nlc3NUb2tlbj1lMmQzNzNkODAxNDM4ZDY5MjhmOGNhMDYzMmJkODUxYw==",
				customAttributes:[],
			},
		]);
	}


  // If cart total is less then 4 > clean free products
	if(cartTotal < 4 && cartTotal > 0 && isFreeGiftInCart && !removedFromCart && bandaidAdded && !userUpdated ){
	  setRemovedFromCart(true);
		setEmptiedCart(false);
		if(!thermometerAdded) {
			setBandaidAdded(false);
			updateLineItem([{id:"Z2lkOi8vc2hvcGlmeS9DaGVja291dExpbmVJdGVtLzM5NDc0MDAxMTE3MjY4MD9jaGVja291dD0wZTcxODA5NjM0YjMxMTk0M2E5MTRlOWI1ZTRlZWI0Yw==", quantity: 0}]);
		}
		if(thermometerAdded) {
			setThermometerAdded(false);
			updateLineItem([{id:"Z2lkOi8vc2hvcGlmeS9DaGVja291dExpbmVJdGVtLzM5NDc0MDAxMTUwMDM2MD9jaGVja291dD0wZTcxODA5NjM0YjMxMTk0M2E5MTRlOWI1ZTRlZWI0Yw==", quantity: 0}]);
		}
	}

	// reset user action
	const resetUserInteraction = () => {
		setUserUpdated(false);
	}

	// Switch free cart item - this function is triggered from CartItem.jsx
	const switchFreePurchaseItem = async (type) => {

			setUserUpdated(true);
			setCartUpdated(false);

			const bandaidEncodedVariant = encode(
	 		 'ProductVariant',
	 		 39474001117268,
	 		 {
	 			 accessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_API_TOKEN,
	 		 },
	 	 );

	 	 // Thermometer
	 	 const thermometerEncodedVariant = encode(
	 		 'ProductVariant',
	 		 39474001150036,
	 		 {
	 			 accessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_API_TOKEN,
	 		 },
	 	 );

		 if(type === "bandaids") {
			  setBandaidAdded(true);
			  setThermometerAdded(false);
			  const deleteItem = await  updateLineItem([{id:"Z2lkOi8vc2hvcGlmeS9DaGVja291dExpbmVJdGVtLzM5NDc0MDAxMTUwMDM2MD9jaGVja291dD0wZTcxODA5NjM0YjMxMTk0M2E5MTRlOWI1ZTRlZWI0Yw==", quantity: 0}]);
			 addItemAndOpenCart([
	 			{
	 				quantity: 1,
	 				variantId: bandaidEncodedVariant,
	 				customAttributes:[],
	 			},
	 		]);
		 }

		 if(type === "thermometer") {
			  setBandaidAdded(false);
			  setThermometerAdded(true);
			  const deleteItem = await  updateLineItem([{id:"Z2lkOi8vc2hvcGlmeS9DaGVja291dExpbmVJdGVtLzM5NDc0MDAxMTE3MjY4MD9jaGVja291dD0wZTcxODA5NjM0YjMxMTk0M2E5MTRlOWI1ZTRlZWI0Yw==", quantity: 0}]);

			 addItemAndOpenCart([
				 {
					 quantity: 1,
					 variantId: thermometerEncodedVariant,
					 customAttributes:[],
				 },
			 ]);
		 }
	}


	const cartItemRows = lineItems.map((item, index) => (
		<div
			key={item.id}
			className="reveal__slide reveal__slide--takeover mb3"
			style={{
				transitionDelay: isCartOpen
					? `${index * 200 + 200}ms`
					: `${TRANSITION_DURATION}ms`,
			}}>
			<CartItem
				url={`/products/${item.variant.product.handle}`}
				id={item.id}
				title={item.title}
				quantity={item.quantity}
				discounts={item.discountAllocations}
				image={item.smallImage}
				options={item.variant.selectedOptions}
				variants={item.variants}
				sku={item.variant.sku}
				variantTitle={item.variant.title}
				cents={Number(item.variant.price.amount) * 100}
				productId={decode(item.variant.product.id).id}
				customAttributes={item.customAttributes}
				isUpdatingCheckout={isUpdatingCheckout}
				isSubscriptionActive={isSubscriptionActive}
				removeLineItem={removeLineItem}
				updateLineItem={updateLineItem}
				addItemAndOpenCart={addItemAndOpenCart}
				lineItems={lineItems}
				switchFreePurchaseItem={switchFreePurchaseItem}
				cartTotal={cartTotal}
				resetUserInteraction={resetUserInteraction}
			/>
		</div>
	));

	const shippingNote = isFreeShipping ? (
		<p>Congratulations! You’ve earned free shipping.</p>
	) : (
		<p>
			You’re{' '}
			<span className="fw--800">
				{centsToPriceNoTrailingZeros(
					freeShippingMinimumCents - subtotalCents,
				)}
			</span>{' '}
			away from free shipping.
		</p>
	);

	const bulkNote = isBulkDiscount ? (
		<p className="sans--sm-md">
			Congratulations! You’ve earned{' '}
			<span className="fw--800">{bulkDiscountPercentage}%</span> off your
			medicine cabinet.
		</p>
	) : (
		<p className="sans--sm-md">
			Add {bulkDiscountMinimumItems - cartCount} more items for{' '}
			<span className="fw--800">{bulkDiscountPercentage}%</span> off your
			medicine cabinet.
		</p>
	);

	const bulkNoteProgress = !isEmpty && !isSubscriptionActive && (
		<div className="mb3">
			<div className="mb1">{bulkNote}</div>
			<ProgressBar percentage={cartCount / bulkDiscountMinimumItems} />
		</div>
	);

	const medicineCabinetNote = !isEmpty && !isBulkDiscount && (
		<div className="df fdr bt--black pt2">
			<div className="fg1">
				<div className="rich-text mb1 sans--sm-md">
					<BlockContent
						blocks={medicineCabinetTitle}
						serializers={richText}
					/>
				</div>
				<div className="rich-text">
					<BlockContent
						blocks={medicineCabinetDescription}
						serializers={richText}
					/>
				</div>
			</div>
			<div className="fg0 pl1 ws--nowrap">
				<Link
					aria-label={medicineCabinetCta}
					to="/cabinets/new"
					onClick={closeCart}
					className="button--pill--primary invert">
					{medicineCabinetCta}
				</Link>
			</div>
		</div>
	);

  //
	const relatedProductsSection = !isEmpty && relatedProducts.length > 0 && (
		<div className="bt--black pt2 mb2 reveal__slide reveal__slide--takeover reveal__delay--2">
			<h3 className="fw--800 mb2">{relatedProductsTitle}</h3>
			<div className="row gutter--vertical">
				{relatedProducts.map((product, index) => {

					const discountVariant = getWholesaleVariant(
						product.variants,
						customer,
					);
					const cents = discountVariant.cents;
					const variantId = discountVariant.variantId;

					const onAdd = () => {
						const app = getApp();
						const {subscription} = product;
						const customAttributes = [];

						if (subscription) {
							customAttributes.push({
								key: '_subscription_options',
								value: JSON.stringify(subscription),
							});
						}

						const encodedVariantId = encode(
							'ProductVariant',
							variantId,
							{
								accessToken:
									process.env
										.GATSBY_SHOPIFY_STOREFRONT_API_TOKEN,
							},
						);

						// Track add to cart
						if (app.analytics) {
							app.analytics.addToCart({
								_productId: product.productId,
								id: discountVariant.sku,
								name: product.title,
								brand: 'Genexa',
								category: 'Medication',
								variant: discountVariant.title,
								quantity: 1,
								price: Number(discountVariant.price),
							});
						}

						return addItemAndOpenCart([
							{
								quantity: 1,
								variantId: encodedVariantId,
								customAttributes,
							},
						]);
					};

					return (
						<div key={index} className="col c4">
							<ProductTileRelatedCart
								image={product.mainImageInverted}
								title={product.title}
								cents={cents}
								slug={product.slug}
								isAdding={isAddingItem}
								onAdd={onAdd}
							/>
						</div>
					);
				})};
			</div>
		</div>
	);

	const cartFooter = state => (
		<div
			ref={cartFooterEl}
			style={{
				...CART_DEFAULT_STYLE,
				...CART_TRANSITION_STYLES[state],
			}}
			className="cart__footer bg--white p2">
			<div className="df fdr jcb fw--800 mb1">
				<div>Total</div>
				<div>{centsToPriceNoTrailingZeros(subtotalCents)}</div>
			</div>
			<div className="mb2">{shippingNote}</div>
			<div className="row gutter--vertical gutter--small gutter--desktop--lg">
				<div className="col">
					<button
						title="Checkout"
						aria-label="Checkout"
						disabled={isNavigatingToCheckout}
						onClick={navigateToCheckout}
						name="checkout"
						className={classNames(
							'db x mb2 button--primary invert',
							{
								loading: isNavigatingToCheckout,
							},
						)}
						style={{
							backgroundColor: '#59abe5',
							'--borderColor': '#59abe5',
						}}>
						Checkout
					</button>
				</div>
				<div className="col c6">
					<button
						title="Paypal Checkout"
						aria-label="Paypal Checkout"
						disabled={isNavigatingToCheckout}
						onClick={navigateToCheckout}
						name="checkout"
						className={classNames('db x mb2 button--secondary', {
							loading: isNavigatingToCheckout,
						})}>
						<img
							className="db mxa cart__paypal"
							src="/images/paypal.png"
							alt="Paypal"
						/>
					</button>
				</div>
				<div className="col c6">
					<button
						title="Amazon Checkout"
						aria-label="Amazon Checkout"
						disabled={isNavigatingToCheckout}
						onClick={navigateToCheckout}
						name="checkout"
						className={classNames('db x mb2 button--secondary', {
							loading: isNavigatingToCheckout,
						})}>
						<img
							className="db mxa cart__amazon"
							src="/images/amazon.png"
							alt="Amazon"
						/>
					</button>
				</div>
			</div>
		</div>
	);

	return (
		<Transition
			/*in={isCartOpen}*/
			in={false}
			mountOnEnter
			unmountOnExit
			appear
			timeout={{
				enter: 0,
				exit: TRANSITION_DURATION,
			}}>
       {state => (
				<React.Fragment>
					<div
						onClick={closeCart}
						className="cart__underlay bg--black"
						style={{
							...UNDERLAY_DEFAULT_STYLE,
							...UNDERLAY_TRANSITION_STYLES[state],
						}}
					/>
					<div
						className="cart bg--gray-bg"
						style={{
							...CART_DEFAULT_STYLE,
							...CART_TRANSITION_STYLES[state],
						}}>
						<div
							className="cart__scroll ofy--scroll"
							style={{
								paddingBottom: footerHeight
									? footerHeight
									: null,
							}}>
							<div className="header__height" />
							<Reveal>
								<div className="p2">
									{emptyCartBody}
									{bulkNoteProgress}
									<div className="push-up">
										{cartItemRows}
									</div>
									{relatedProductsSection}
									{medicineCabinetNote}
								</div>
							</Reveal>
						</div>
					</div>
					<div
						style={{
							...CART_DEFAULT_STYLE,
							...CART_TRANSITION_STYLES[state],
						}}
						className="cart__header header__height bg--white df fdr pl2 pr2 jcb aic">
						<p>
							<span className="fw--800">Your Bag</span>
							<span>
								{' '}
								{cartCount} {pluralize('Item', cartCount)}
							</span>
						</p>

						<button
							title="Close Cart"
							aria-label="Close Cart"
							name="close cart"
							className="db link--opacity p1"
							onClick={closeCart}>
							<img
								alt="close cart"
								className="db"
								src="/images/icon-close-cart.svg"
							/>
						</button>
					</div>
					{!isEmpty ? cartFooter(state) : null}
				</React.Fragment>
				)}
		</Transition>
	);
};

const getDiscountedSubtotal = (runningTotal, item) => {
	const {
		customAttributes,
		quantity,
		variant,
		discountAllocations = [],
	} = item;
	const cents = Number(variant.price.amount) * 100;
	let activeCents = cents;

	if (discountAllocations.length > 0) {
		activeCents = reduce(
			discountAllocations,
			(activeCents, discount) => {
				const nextDiscountAmount =
					(Number(discount.allocatedAmount.amount) * 100) / quantity;

				return activeCents - nextDiscountAmount;
			},
			cents,
		);
	}

	const subscriptionId = find(customAttributes, {
		key: 'subscription_id',
	});

	let subscriptionOptions = find(customAttributes, {
		key: '_subscription_options',
	});

	const isSubscription = !!subscriptionId;

	if (subscriptionOptions) {
		try {
			subscriptionOptions = JSON.parse(subscriptionOptions.value);
		} catch (error) {
			console.log(error);
		}
	}

	if (isSubscription) {
		const subscriptionCents =
			subscriptionOptions.discountType === 'percentage'
				? cents -
				  (Number(subscriptionOptions.discountAmount) / 100) * cents
				: cents - Number(subscriptionOptions.discountAmount) * 100;

		return runningTotal + subscriptionCents * quantity;
	} else {
		return runningTotal + activeCents * quantity;
	}
};

const mapStateToProps = ({
	checkout,
	isNavigatingToCheckout,
	isCartOpen,
	isUpdatingCheckout,
	isAddingItem,
	customer,
}) => ({
	updatedAt: checkout ? checkout.updatedAt : null,
	lineItems: checkout ? checkout.lineItems : [],
	subtotalCents: checkout
		? reduce(checkout.lineItems, getDiscountedSubtotal, 0)
		: 0,
	discounts: checkout ? checkout.discountApplications : [],
	cartCount: checkout
		? reduce(
				checkout.lineItems,
				(runningTotal, item) => item.quantity + runningTotal,
				0,
		  )
		: 0,
	isNavigatingToCheckout,
	isCartOpen,
	isUpdatingCheckout,
	isAddingItem,
	customer,
});

const mapStoreToProps = ({
	closeCart,
	navigateToCheckout,
	updateLineItem,
	removeLineItem,
	addItemAndOpenCart,
}) => ({
	closeCart,
	navigateToCheckout,
	updateLineItem,
	removeLineItem,
	addItemAndOpenCart,
});

export default connectToStore(mapStateToProps, mapStoreToProps)(Cart);
