import React from 'react';

import {compose} from 'recompose';
import {connectToStore} from '../components/Provider';
import clientSideComponent from '../middleware/clientSideComponent';

import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';

const CookiesBanner = ({
	cookiesDisclaimer,
	acceptCookies,
	location
}) => (
	<div
		className='cookies-disclaimer df fw jcc aic tc p1 p0--md'>
			<div className='sans--xs sans--sm--md rich-text'>
				<BlockContent blocks={cookiesDisclaimer.disclaimer} serializers={richText} />
			</div>
			<button title="Accept Cookies" aria-label="Accept Cookies"  className='ml2--lg di fw--800 sans--xs sans--sm--md' onClick={acceptCookies}>Accept Cookies</button>
	</div>
);

const mapStoreToProps = ({acceptCookies}) => ({
	acceptCookies,
});

const mapStateToProps = () => {};

export default compose(
	clientSideComponent,
	connectToStore(mapStateToProps, mapStoreToProps),
)(CookiesBanner);
