import {getCustomerDetails} from '../../api/account';

const hydrateCustomerDetails = store => () => {
	const {customerToken, rechargeAccessToken} = store.getState();

	if (!customerToken || !rechargeAccessToken) return;

	store.setState({isFetchingAccountDetails: true});

	return getCustomerDetails({token: customerToken, rechargeAccessToken})
		.then(res => {
			if (!res.ok) {
				throw res;
			} else {
				return res.json();
			}
		})
		.then(({rechargeCustomer, paymentMethod}) => {
			store.setState({
				rechargeCustomer,
				paymentMethod,
				isFetchingAccountDetails: false,
			});
		})
		.catch(error => {
			store.setState({
				isFetchingAccountDetails: false,
			});
		});
};

export default hydrateCustomerDetails;
