import React from 'react';
import classNames from 'classnames';
import Link from './Link';
import {navigate} from 'gatsby';
import {Transition} from 'react-transition-group';
import {connectToStore} from './Provider';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import InlineForm from './InlineForm';
import Reveal from './Reveal';

const TRANSITION_DURATION = 600;

const DRAWER_DEFAULT_STYLE = {
	transition: `transform ${TRANSITION_DURATION}ms cubic-bezier(0, 0, 0.25, 1)`,
	transform: 'translate3d(100%, 0, 0)',
};

const DRAWER_TRANSITION_STYLES = {
	entering: {transform: 'translate3d(100%, 0, 0)'},
	entered: {transform: 'translate3d(0, 0, 0)'},
	exited: {transform: 'translate3d(100%, 0, 0)'},
	exiting: {transform: 'translate3d(100%, 0, 0)'},
};

const UNDERLAY_DEFAULT_STYLE = {
	transition: `opacity ${TRANSITION_DURATION}ms ease-in-out`,
	opacity: 0,
};

const UNDERLAY_TRANSITION_STYLES = {
	entering: {opacity: 0},
	entered: {opacity: 0.1},
	exited: {opacity: 0},
	exiting: {opacity: 0},
};

const Takeover = ({
	searchPlaceholder,
	takeoverLinks = [],
	contactTitle,
	contactDescription = [],
	isTakeoverOpen,
	closeTakeover = () => {},
}) => {
	const searchForm = (
		<div className="mb5">
			<InlineForm
				onSubmit={query => {
					navigate(`/search?query=${encodeURIComponent(query)}`);
				}}
				submitCta="Search"
				placeholder={searchPlaceholder}
				aria-label={searchPlaceholder}
			/>
		</div>
	);

	const menuRows = takeoverLinks.map((link, index) => {
		if (link._type === 'childRow') {
			return link.menu.map(link => {
				if (link._type === 'grandChildRow') {
					return link.menu.map(link => (
						<Link
							{...link}
							key={link._key}
							className="ml2 mb1 db link--opacity color--gray-text" aria-label="Search"
						/>
					));
				}

				return (
					<Link
						{...link}
						key={link._key}
						className="ml1 mb1 db link--opacity" aria-label="Search"
					/>
				);
			});
		}

		return (
			<Link
				{...link}
				key={link._key}
				className={classNames('mb1 db link--opacity fw--800', {
					mt4:
						index > 0 &&
						takeoverLinks[index - 1] &&
						(takeoverLinks[index - 1]._type === 'childRow' ||
							takeoverLinks[index - 1]._type === 'grandChildRow'),
				})}
				aria-label="Search"
			/>
		);
	});

	const contactSection = (
		/*<div className="mt4">
			<h2 className="fw--800 mb1">{contactTitle}</h2>
			<div className="rich-text line-break mr5--md">
				<BlockContent
					blocks={contactDescription}
					serializers={richText}
				/>
			</div>
		</div>*/
		<></>
	);

	return (
		<React.Fragment>
			<Transition
				in={isTakeoverOpen}
				mountOnEnter
				unmountOnExit
				appear
				timeout={{
					enter: 0,
					exit: TRANSITION_DURATION,
				}}>
				{state => (
					<div
						onClick={closeTakeover}
						className="takeover__underlay bg--black"
						style={{
							...UNDERLAY_DEFAULT_STYLE,
							...UNDERLAY_TRANSITION_STYLES[state],
						}}
					/>
				)}
			</Transition>
			<Transition
				in={isTakeoverOpen}
				timeout={{
					enter: 0,
					exit: TRANSITION_DURATION,
				}}>
				{state => (
					<React.Fragment>
						<div
							className="takeover bg--white"
							style={{
								...DRAWER_DEFAULT_STYLE,
								...DRAWER_TRANSITION_STYLES[state],
							}}>
							<div className="takeover__scroll ofy--scroll">
								<div className="header__height" />
								<Reveal isRevealed={isTakeoverOpen}>
									<div className="p2">
										<div
											className="reveal__slide reveal__slide--takeover"
											style={{
												transitionDelay: isTakeoverOpen
													? '400ms'
													: `${TRANSITION_DURATION}ms`,
											}}>
											{searchForm}
										</div>
										<div
											className="reveal__slide reveal__slide--takeover"
											style={{
												transitionDelay: isTakeoverOpen
													? '600ms'
													: `${TRANSITION_DURATION}ms`,
											}}>
											{menuRows}
											{contactSection}
										</div>
									</div>
								</Reveal>
							</div>
						</div>
						<div
							style={{
								...DRAWER_DEFAULT_STYLE,
								...DRAWER_TRANSITION_STYLES[state],
							}}
							className="takeover__header header__height bg--white df fdr pl2 pr2 jcb aic">
							<img
								className="db"
								src="/images/logo-takeover.svg"
								alt="Takeover"
							/>

							<button
								title="Close Menu"
								aria-label="Close Menu"
								name="close menu"
								className="db link--opacity p1"
								onClick={closeTakeover}>
								<img
									alt="close menu"
									className="db"
									src="/images/icon-close-cart.svg"
								/>
							</button>
						</div>
					</React.Fragment>
				)}
			</Transition>
		</React.Fragment>
	);
};

const mapStateToProps = ({isTakeoverOpen}) => ({
	isTakeoverOpen,
});

const mapStoreToProps = ({closeTakeover}) => ({
	closeTakeover,
});

export default connectToStore(mapStateToProps, mapStoreToProps)(Takeover);
