import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import {connectToStore} from './Provider';
import clientSideComponent from '../middleware/clientSideComponent';
import {compose} from 'recompose';

const Banner = ({banner, closeBanner, hasClosedBanner, bannerOverride}) => {
	if (hasClosedBanner) return null;
	
	return (
		<div className="banner--ribbon of--hidden">
			<div className="banner tc df fdr aic pl1 pr1">
				<div className="fg1 rich-text banner__label ws--nowrap">
				  { !bannerOverride &&
						<BlockContent blocks={banner} serializers={richText} />
					}
					{ bannerOverride &&
						bannerOverride
					}
				</div>
				<button title="Close banner" aria-label="Close banner"  className="fg0 db" onClick={closeBanner}>
					<img
						className="db"
						src="/images/icon-close-filter.svg"
						alt="arrow"
					/>
				</button>
			</div>
		</div>
	);
};

const mapStateToProps = ({hasClosedBanner}) => ({
	hasClosedBanner,
});

const mapStoreToProps = ({closeBanner}) => ({
	closeBanner,
});

export default compose(
	clientSideComponent,
	connectToStore(mapStateToProps, mapStoreToProps),
)(Banner);
