import React, {useState} from 'react';
import classNames from 'classnames';
import {Transition} from 'react-transition-group';
import find from 'lodash/find';

export const TRANSITION_DURATION = 400;

const TRANSITION_STYLES = {
	default: {
		transition: `opacity ${TRANSITION_DURATION}ms ease, transform ${TRANSITION_DURATION}ms cubic-bezier(0, 0, 0.25, 1)`,
	},
	entering: {
		opacity: 0,
		transform: 'translateY(10px)',
	},
	entered: {
		opacity: 1,
		transform: 'translateY(0)',
	},
	exiting: {
		opacity: 0,
		transform: 'translateY(10px)',
	},
	exited: {
		opacity: 0,
		transform: 'translateY(10px)',
	},
};

export default ({
	options = [],
	value,
	onChange = () => {},
	label = 'Select',
	triggerClassName = 'fw--800',
	dropdownAlignment = 'top left',
	dropdownClassName,
	labelClassName,
	optionClassName,
	fieldname,
	...props
}) => {
	const [isOpen, setOpen] = useState(false);
	const selectedOption = find(options, {value});
	const handleSelectClick = () => {
		setOpen(!isOpen);
	  };
	
	  const handleSelectFocus = () => {
		setOpen(true);
	  };

	const trigger = (
		<select
			name={fieldname}
			title={label}
			aria-label={label}
			className={triggerClassName}
			onClick={handleSelectClick}
			onFocus={handleSelectFocus} // Open dropdown on focus
			onBlur={() => setOpen(false)} // Close dropdown on blur
			>
			<option className={labelClassName} value={selectedOption ? selectedOption.title : ''} >
				{selectedOption ? selectedOption.title : label}
			</option>
			<img
				className={classNames('drawer__arrow-flip ml1', {
					active: isOpen,
				})}
				src="/images/icon-arrow-drawer.svg"
				alt="arrow"
			/>
		</select>
	);

	const dropdown = !!options.length && (
		<Transition
			in={isOpen}
			mountOnEnter
			unmountOnExit
			timeout={{
				enter: 100,
				exit: TRANSITION_DURATION,
			}}>
			{status => (
				<div
					style={{
						...TRANSITION_STYLES.default,
						...TRANSITION_STYLES[status],
					}}
					className={classNames('pa pt1 z1', dropdownAlignment)}>
					<div
						className={classNames(
							'input--select__dropdown p1 ofy--scroll',
							dropdownClassName,
						)}>
						{options.map(option => (
							<button
								title={option.title}
								aria-label={option.title}
								type="button"
								onClick={() => {
									onChange(option.value);
									setOpen(false);
								}}
								className={classNames(
									'db x tl',
									optionClassName,
									{
										'fw--800': option.value === value,
									},
								)}>
								{option.title}
							</button>
						))}
					</div>
				</div>
			)}
		</Transition>
	);

	return (
		<div {...props}>
			{trigger}
			<div className="pr">{dropdown}</div>
		</div>
	);
};
