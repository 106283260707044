import React, {Component} from 'react';
import isBoolean from 'lodash/isBoolean';

export default class Drawer extends Component {
	static defaultProps = {
		renderTrigger: ({onClick}) => <button title="Drawer" aria-label="Drawer" onClick={onClick} />,
		transition: 'height 400ms cubic-bezier(.12,.67,.53,1)',
		openByDefault: false,
	};

	constructor(props) {
		super();

		this.state = {
			isOpen: props.openByDefault || false,
			height: 0,
		};
	}

	componentDidMount() {
		global.addEventListener('resize', this.onResize);
		this.onResize();
	}

	componentDidUpdate = (previousProps) => {
		if (this.props.children !== previousProps.children) {
			this.onResize();
		}
	}

	componentWillUnmount() {
		global.removeEventListener('resize', this.onResize);
	}

	onResize = () => {
		if (!this.heightEl) return;

		this.setState({
			height: this.heightEl.getBoundingClientRect().height,
		});
	}

	onClickTrigger = () => {
		this.onResize();
		this.setState({isOpen: !this.state.isOpen});
	}

	render() {
		const {
			renderTrigger,
			children,
			transition,
			isOpen,
		} = this.props;

		const {
			height
		} = this.state;

		const statefulOpen = isBoolean(isOpen) ? isOpen : this.state.isOpen;

		return (
			<div>
				{renderTrigger({
					onClick: this.onClickTrigger,
					isOpen: statefulOpen,
					title: this.props.title,
				})}
				<div
					style={{
						height: statefulOpen ? height : 0,
						transition,
					}}
					className="of--hidden">
					<div
						ref={el => this.heightEl = el}>
						{children}
					</div>
				</div>
			</div>
		);
	}
}
