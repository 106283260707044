import React from 'react';
import classNames from 'classnames';
import Link from './Link';
import richText from '../serializers/richText';
import BlockContent from '@sanity/block-content-to-react';
import NewsletterInputFooter from './NewsletterInputFooter';

export default ({desktopMenu, mobileMenu, legal, newsletter}) => {
	const {columns: desktopColumns, certifications} = desktopMenu;
	const {columns: mobileColumns, infoLinks, socialLinks} = mobileMenu;

	return (
		<footer className="grid-container contained mt4 mb6--lg mt12--lg">
			<div className="row align--center">
				<div className="col c10--xl">
				{/*	<div className="row mb6 mb10--lg">
						<div className="col c5--lg mb4 mb0--lg">
							<h2 className="serif--lg serif--xxl--lg  pr6--xl">
								{newsletter.title}
							</h2>
						</div>
						<div className="col c7--lg">
							{ <NewsletterInputFooter {...newsletter} /> }
						</div>
					</div>*/}

					<hr className="mb3 mb6--lg" />
					<a aria-label="Site Map" href="https://www.genexa.com/sitemap.xml" class="full-hide">Sitemap</a> {/* Sitemap link accessibility */}
					{/* DESKTOP */}
					<div className="df fdr show--lg">
						{desktopColumns.map(({_key, title, links}) => (
							<div
								key={_key}
								className="footer__col--desktop mb6">
								<h4 className="fw--800 mb4">{title}</h4>
								<ul>
									{links.map(link => (
										<li className="mb1" key={link._key}>
											{delete link._key}
											{delete link.page}
											{delete link.collection}
											<Link
												aria-label={title}
												className={link.title === 'Privacy Policy' || link.title === 'Cookie Policy' ? "link--underline-small iubenda-embed" : "link--underline-small"}
												{...link}
											/>
											</li>
									))}
								</ul>
							</div>
						))}
						<div className="footer__col--desktop mb6">
							<h4 className="fw--800 mb4">
								{certifications.title}
							</h4>
							<ul className="df fw">
								{certifications.images.map(
									({_key, alt, url}) => (
										<li key={_key} className="mr2 mb2">
											<img
												alt={alt}
												className="db footer__certification"
												src={url}
											/>
										</li>
									),
								)}
							</ul>
						</div>
					</div>

					{/* MOBILE */}
					<div className="row hide--lg">
						{mobileColumns.map(({_key, title, links}) => (
							<div key={_key} className="col c6 mb6">
								<div className="sans--xs fw--800 mb2">
									{title}
								</div>
								<ul>
									{links.map(link => (
										<li
											className="sans--xs mb1"
											key={link._key}>
											<Link
												aria-label={title}
												className={link.title === 'Privacy Policy' || link.title === 'Cookie Policy' ? "link--underline-small iubenda-embed" : "link--underline-small"}
												{...link}
											/>
										</li>
									))}
								</ul>
							</div>
						))}

						<div className="col mb6 hide--lg">
							<div className="sans--xs fw--800">
								{socialLinks.title}
							</div>
							<div className="df fw">
								{socialLinks.links.map(
									({_key, image, link}, index) => (
										<Link
											aria-label="Social media"
											key={_key}
											{...link}
											className={classNames(
												'db link--opacity p2',
												{
													pl0: index === 0,
												},
											)}>
											<img
												alt={image.alt}
												className=""
												src={image.url}
											/>
										</Link>
									),
								)}
							</div>
						</div>
					</div>

					<div className="hide--lg bt--black pt2 pb2">
						<div className="sans--xs rich-text line-break">
							<BlockContent
								blocks={infoLinks}
								serializers={richText}
							/>
						</div>
					</div>

					<div className="pt2 pb2 bt--black">
						<p className="sans--xs">{legal.title}</p>
						<div className="pt1 rich-text sans--xs line-break">
							<BlockContent
								blocks={legal.description}
								serializers={richText}
							/>
						</div>
					</div>

					<div className="sans--xs hide--lg bt--black pt2 pb2">
						<p>Made in the USA</p>
					</div>
				</div>
			</div>
			<div id="amzn-bwp-cart" data-site-id="8chlcznvx9" data-widget-id="w-eBKEkAAfBm330FAemKJH21"></div>
			<script async fetchpriority="high" src="https://code.buywithprime.amazon.com/bwp.v1.js"></script>			
		</footer>
	);
	
};
