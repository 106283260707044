const {fetch} = global;

export const createRechargeCheckout = ({
	lineItems = [],
	shopifyCheckoutId = '',
}) => {
	let endpoint = `${process.env.GATSBY_FUNCTION_PATH}/create-recharge-checkout`;

	return fetch(endpoint, {
		method: 'POST',
		body: JSON.stringify({
			lineItems,
			shopifyCheckoutId,
		})
	})
	.then(res => res.json());
};