import React, {Component} from 'react';

export default UniversalComponent =>
	class ClientOnlyComponent extends Component {
		constructor() {
			super();
			this.state = {
				isMounted: false,
			};
		}

		componentDidMount = () => {
			this.mount();
		};

		mount = () => {
			this.setState({
				isMounted: true,
			});
		};

		render() {
			if (!this.state.isMounted) return null;

			return <UniversalComponent {...this.props} />;
		}
	};
