// Polyfills
require('unfetch');

const React = require('react');
const Sentry = require('@sentry/browser');
const Layout = require('./src/components/Layout').default;
const Provider = require('./src/components/Provider').default;
const LocaleProvider = require('./src/components/LocaleProvider').default;
const createStore = require('./src/stores/AppStore').default;
const app = require('magic-tricks/lib/getApp').default();
const routeDuration = require('./src/components/Layout').TRANSITION_DURATION;
const Analytics = require('./src/components/Analytics').initialize;

// Config Sentry
Sentry.init({
	dsn: process.env.GATSBY_SENTRY_DSN,
	// This environment variable is provided via Webpack configuration
	// @see ./gatsby-node.js
	release: process.env.GITCOMMIT,
});

// Setup analytics
app.analytics = Analytics({
	googleAnalyticsPropertyId: process.env.GATSBY_GOOGLE_ANALYTICS_PROPERTY,
	googleLinkerDomains: ['genexa.com', 'genexa.netlify.app', 'localhost:8888'],
});

// Create store
app.store = createStore();

// Start firing side effects
app.store.hydratePassword();
app.store.hydrateBanner();
app.store.hydrateCookiesConsent();
app.store.hydratePopup();
app.store.hydrateCheckout();
app.store.hydrateTokenFromCache();
app.store.hydrateCustomer();

exports.onRouteUpdate = ({location, previousLocation, ...props}) => {
	// Close Cart/Takeover
	app.store.closeCart();
	app.store.closeTakeover();

	// Track page views
	app.analytics.pageview(location);
	global.scrollTo(0, 0);
};

// Wrap every page with the main layout
exports.wrapPageElement = ({element, props}) => (
	<LocaleProvider {...props}>
		<Layout {...props}>{element}</Layout>
	</LocaleProvider>
);

// Wrap application with provider
exports.wrapRootElement = ({element, props}) => (
	<Provider {...props} store={app.store}>
		{element}
	</Provider>
);

// Delay updating scroll
exports.shouldUpdateScroll = ({prevRouterProps, routerProps}) => {
	// const isAccount = routerProps.location.pathname.indexOf('/account') === 0;
	// const routeTransition = isAccount ? 0 : routeDuration;
	//
	// if (routerProps.location.pathname !== prevRouterProps.location.pathname) {
	// 	setTimeout(() => {
	// 		global.scrollTo(0, 0);
	// 	}, routeTransition);
	// }
	return false;
};
{/* cookie and privacy policy script */}
exports.onClientEntry = () => {
	if (typeof window !== 'undefined') {
	  (function(w, d) {
		var loader = function() {
		  var s = d.createElement("script"),
			tag = d.getElementsByTagName("script")[0];
		  s.src = "https://cdn.iubenda.com/iubenda.js";
		  tag.parentNode.insertBefore(s, tag);
		};
		if (w.addEventListener) {
		  w.addEventListener("load", loader, false);
		} else if (w.attachEvent) {
		  w.attachEvent("onload", loader);
		} else {
		  w.onload = loader;
		}
	  })(window, document);
	}
  };

// Site Credit
console.log(`
Site Credit
===========
Design : https://grandarmy.com
Development : https://gardener.nyc
`);
