import * as account from '../../api/account';

const skipNextCharge = store => ({subscriptionId}) => {
	const {customerToken, rechargeAccessToken} = store.getState();

	store.setState({
		isHydratingSubscriptions: true,
	});

	if (!customerToken || !rechargeAccessToken) return;

	return account
		.skipNextCharge({
			rechargeAccessToken,
			subscriptionId,
		})
		.then(res => {
			if (!res.ok) {
				throw res;
			} else {
				return res.json();
			}
		})
		.then(({rechargeCustomer}) => {
			store.setState({
				// rechargeCustomer,
				isHydratingSubscriptions: false,
			});
		})
		.catch(error => {
			store.setState({
				isHydratingSubscriptions: false,
			});
		});
};

export default skipNextCharge;
