// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-home-jsx": () => import("./../src/templates/Home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-page-jsx": () => import("./../src/templates/Page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-compare-page-jsx": () => import("./../src/templates/ComparePage.jsx" /* webpackChunkName: "component---src-templates-compare-page-jsx" */),
  "component---src-templates-compare-brand-page-jsx": () => import("./../src/templates/CompareBrandPage.jsx" /* webpackChunkName: "component---src-templates-compare-brand-page-jsx" */),
  "component---src-templates-compare-category-page-jsx": () => import("./../src/templates/CompareCategoryPage.jsx" /* webpackChunkName: "component---src-templates-compare-category-page-jsx" */),
  "component---src-templates-compare-search-jsx": () => import("./../src/templates/CompareSearch.jsx" /* webpackChunkName: "component---src-templates-compare-search-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../src/templates/Blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-product-jsx": () => import("./../src/templates/Product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */),
  "component---src-templates-collection-jsx": () => import("./../src/templates/Collection.jsx" /* webpackChunkName: "component---src-templates-collection-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../src/templates/BlogPost.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-blog-author-jsx": () => import("./../src/templates/BlogAuthor.jsx" /* webpackChunkName: "component---src-templates-blog-author-jsx" */),
  "component---src-templates-account-jsx": () => import("./../src/templates/Account.jsx" /* webpackChunkName: "component---src-templates-account-jsx" */),
  "component---src-templates-search-jsx": () => import("./../src/templates/Search.jsx" /* webpackChunkName: "component---src-templates-search-jsx" */),
  "component---src-templates-bundles-jsx": () => import("./../src/templates/Bundles.jsx" /* webpackChunkName: "component---src-templates-bundles-jsx" */),
  "component---src-templates-404-jsx": () => import("./../src/templates/404.jsx" /* webpackChunkName: "component---src-templates-404-jsx" */),
  "component---src-templates-preview-jsx": () => import("./../src/templates/Preview.jsx" /* webpackChunkName: "component---src-templates-preview-jsx" */)
}

