export const register = (
	firstName,
	lastName,
	email,
	password,
	acceptsMarketing,
) =>
	fetch(`${process.env.GATSBY_FUNCTION_PATH}/account-register`, {
		method: 'POST',
		body: JSON.stringify({
			firstName,
			lastName,
			email,
			password,
			acceptsMarketing,
		}),
	});

export const activate = (id, token, password) =>
	fetch(`${process.env.GATSBY_FUNCTION_PATH}/account-activate`, {
		method: 'POST',
		body: JSON.stringify({
			id,
			token,
			password,
		}),
	});

export const reset = (id, token, password) =>
	fetch(`${process.env.GATSBY_FUNCTION_PATH}/account-reset`, {
		method: 'POST',
		body: JSON.stringify({
			id,
			token,
			password,
		}),
	});

export const login = (email, password) =>
	fetch(`${process.env.GATSBY_FUNCTION_PATH}/account-login`, {
		method: 'POST',
		body: JSON.stringify({
			email,
			password,
		}),
	});

export const logout = token =>
	fetch(`${process.env.GATSBY_FUNCTION_PATH}/account-logout`, {
		method: 'POST',
		body: JSON.stringify({
			token,
		}),
	});

export const recover = email =>
	fetch(`${process.env.GATSBY_FUNCTION_PATH}/account-recover`, {
		method: 'POST',
		body: JSON.stringify({
			email,
		}),
	});

export const getCustomer = token =>
	fetch(`${process.env.GATSBY_FUNCTION_PATH}/account-customer`, {
		method: 'GET',
		headers: {'x-storefront-token': token},
	});

export const getCustomerAccount = token =>
	fetch(`${process.env.GATSBY_FUNCTION_PATH}/account-customer-details`, {
		method: 'GET',
		headers: {'x-storefront-token': token},
	});

export const updateAddress = (token, addressId, address, isDefault) =>
	fetch(
		`${process.env.GATSBY_FUNCTION_PATH}/account-customer-address-update`,
		{
			method: 'POST',
			headers: {'x-storefront-token': token},
			body: JSON.stringify({
				addressId,
				address,
				isDefault,
			}),
		},
	);

export const deleteAddress = (token, addressId) =>
	fetch(
		`${process.env.GATSBY_FUNCTION_PATH}/account-customer-address-delete`,
		{
			method: 'POST',
			headers: {'x-storefront-token': token},
			body: JSON.stringify({
				addressId,
			}),
		},
	);

export const createAddress = (token, address, isDefault) =>
	fetch(
		`${process.env.GATSBY_FUNCTION_PATH}/account-customer-address-create`,
		{
			method: 'POST',
			headers: {'x-storefront-token': token},
			body: JSON.stringify({
				address,
				isDefault,
			}),
		},
	);

//
// === Recharge ===
//

export const getSubscriptions = ({rechargeAccessToken}) =>
	fetch(`${process.env.GATSBY_FUNCTION_PATH}/subscriptions-list`, {
		method: 'GET',
		headers: {'x-recharge-token': rechargeAccessToken},
	});

export const getCustomerDetails = ({token, rechargeAccessToken}) =>
	fetch(`${process.env.GATSBY_FUNCTION_PATH}/account-customer-details`, {
		method: 'GET',
		headers: {
			'x-storefront-token': token,
			'x-recharge-token': rechargeAccessToken,
		},
	});

export const updateShippingAddress = ({
	rechargeAccessToken,
	shippingAddress,
	addressId,
}) =>
	fetch(
		`${process.env.GATSBY_FUNCTION_PATH}/subscriptions-shipping-address-update?addressId=${addressId}`,
		{
			method: 'PUT',
			headers: {'x-recharge-token': rechargeAccessToken},
			body: JSON.stringify({
				shippingAddress,
			}),
		},
	);

export const updateRechargeCustomer = ({rechargeAccessToken, customerData}) =>
	fetch(`${process.env.GATSBY_FUNCTION_PATH}/subscription-customer-update`, {
		method: 'PUT',
		headers: {'x-recharge-token': rechargeAccessToken},
		body: JSON.stringify(customerData),
	});

export const createStripeCustomer = ({paymentMethod}) =>
	fetch(`${process.env.GATSBY_FUNCTION_PATH}/stripe-create-customer`, {
		method: 'POST',
		body: JSON.stringify({paymentMethod}),
	});

export const updateSubscriptionDetails = ({
	rechargeAccessToken,
	addressId,
	subscriptionDetails,
}) =>
	fetch(
		`${process.env.GATSBY_FUNCTION_PATH}/subscription-details-update?addressId=${addressId}`,
		{
			method: 'PUT',
			headers: {'x-recharge-token': rechargeAccessToken},
			body: JSON.stringify({
				subscriptionDetails,
			}),
		},
	);

export const skipNextCharge = ({rechargeAccessToken, subscriptionId}) =>
	fetch(`${process.env.GATSBY_FUNCTION_PATH}/subscription-skip-charge`, {
		method: 'PUT',
		headers: {'x-recharge-token': rechargeAccessToken},
		body: JSON.stringify({
			subscriptionId,
		}),
	});

export const cancelSubscription = ({
	rechargeAccessToken,
	subscriptionId,
	cancelationReason,
}) =>
	fetch(`${process.env.GATSBY_FUNCTION_PATH}/subscription-cancel`, {
		method: 'PUT',
		headers: {'x-recharge-token': rechargeAccessToken},
		body: JSON.stringify({
			subscriptionId,
			cancelationReason,
		}),
	});

export const activateSubscription = ({rechargeAccessToken, subscriptionId}) =>
	fetch(`${process.env.GATSBY_FUNCTION_PATH}/subscription-activate`, {
		method: 'PUT',
		headers: {'x-recharge-token': rechargeAccessToken},
		body: JSON.stringify({
			subscriptionId,
		}),
	});

export const createMultipassUrl = ({email,return_to})=>{
	return fetch(`${process.env.GATSBY_FUNCTION_PATH}/create-shopify-multilogin`, {
			method: 'POST',
			body: JSON.stringify({
				email,
				return_to,
			}),
		})
		.then(res => res.json());
}
