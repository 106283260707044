import React, {useState} from 'react';
import imageAspectRatioStyles from '../utils/imageAspectRatioStyles';
import {defaultSerializers} from '@sanity/block-content-to-react';
import {Link} from 'gatsby';
import {Transition} from 'react-transition-group';
import findAccessiblityColor from '../utils/findAccessiblityColor';

export const MODAL_TRANSITION_DURATION = 800;

const UNDERLAY_TRANSITION_STYLES = {
	default: {
		transition: `opacity ${MODAL_TRANSITION_DURATION}ms ease, transform ${MODAL_TRANSITION_DURATION}ms cubic-bezier(0, 0, 0.25, 1)`,
	},
	entering: {
		opacity: 0,
	},
	entered: {
		opacity: 1,
	},
	exiting: {
		opacity: 0,
	},
	exited: {
		opacity: 0,
	},
};

const MODAL_TRANSITION_STYLES = {
	default: {
		transition: `opacity ${MODAL_TRANSITION_DURATION}ms ease, transform ${MODAL_TRANSITION_DURATION}ms cubic-bezier(0, 0, 0.25, 1)`,
	},
	entering: {
		opacity: 0,
		transform: 'translate(-50%, calc(-50% + 100px))',
	},
	entered: {
		opacity: 1,
		transform: 'translate(-50%, -50%)',
	},
	exiting: {
		opacity: 0,
		transform: 'translate(-50%, calc(-50% + 100px))',
	},
	exited: {
		opacity: 0,
		transform: 'translate(-50%, calc(-50% + 100px))',
	},
};

export default {
	types: {
		block: props => {
			const {style = 'normal',children} = props.node;

			if (style === 'h1') {
				return <h1>{props.children}</h1>;
			} else if (style === 'h2') {
				return <h2>{props.children}</h2>;
			} else if (style === 'h3') {
				return <h3>{props.children}</h3>;
			}
			if (style === 'normal') {
				let resp='';
				for (const property in children) {
					if(children[property].marks){
						if(children[property].marks.includes('highlight')){
							resp=children[property].marks.includes('em')?<p className="highlight"><em>{children[property].text}</em></p>:<p className="highlight">{children[property].text}</p>;
						}
						if(children[property].marks.includes('small')){
							resp=children[property].marks.includes('em')?<small><em>{children[property].text}</em></small>:<small>{children[property].text}</small>;
						}
					}
				}
				return resp?resp:defaultSerializers.types.block(props);
			}

			// Fall back to default handling
			return defaultSerializers.types.block(props);
		},
	},
	marks: {
		link: props => {
			var propsTitle=typeof props.mark.title === 'object' && props.mark.title._type=='localeString' ? props.mark.title.en : props.mark.title;
			if (props.mark.href[0] === '/') {
				return (
					<Link
						aria-label={propsTitle}
						title={propsTitle}
						target={props.mark.openInNewWindow ? '_blank' : null}
						to={props.mark.href}>
						{props.children}
					</Link>
				);
			}
			return (
				<a
					aria-label={propsTitle}
					title={propsTitle}
					target={props.mark.openInNewWindow ? '_blank' : null}
					href={props.mark.href}>
					{props.children}
				</a>
			);
		},
		imageModal: props => {
			const [isOpen, setIsOpen] = useState(false);
			const [isZoomed, setIsZoomed] = useState(false);
			const [zoomPosition, setZoomPosition] = useState([0, 0]);

			const TRANSITION_DURATION = 200;

			const DEFAULT_STYLE = {
				transition: `opacity ${TRANSITION_DURATION}ms ease-in-out`,
				opacity: 0,
			};

			const TRANSITION_STYLES = {
				entering: {opacity: 0},
				entered: {opacity: 1},
				exited: {opacity: 0},
				exiting: {opacity: 0},
			};

			const moveZoomedImageSlider = (e) => {
				e.preventDefault();
				const containerSize = e.target.getBoundingClientRect();
				const mouseX = e.clientX - containerSize.left;
				const mouseY = e.clientY - containerSize.top;
				const xPercent = (mouseX / containerSize.width) * 100;
				const yPercent = (mouseY / containerSize.height) * 100;
				setZoomPosition([xPercent, yPercent]);
			};

			const trigger = (
				<button
					title={props.children}
					aria-label={props.children}
					className="link--inline"
					onClick={() => setIsOpen(true)}>
					{props.children}
				</button>
			);

			const modal = (
				<Transition
					in={isOpen}
					mountOnEnter
					unmountOnExit
					timeout={{
						enter: 100,
						exit: MODAL_TRANSITION_DURATION,
					}}>
					{status => (
						<React.Fragment>
							<div
								className="bundle-builder__underlay pf fill"
								style={{
									...UNDERLAY_TRANSITION_STYLES.default,
									...UNDERLAY_TRANSITION_STYLES[status],
								}}
							/>
							<div
								className="bundle-builder__modal--product pf p2 pt4 pb4 p4--md p6--lg bg--white"
								style={{
									...MODAL_TRANSITION_STYLES.default,
									...MODAL_TRANSITION_STYLES[status],
								}}>
								<div
									style={imageAspectRatioStyles(
										props.mark.image.dimensions.aspectRatio,
									)}
									className="db image mb3 image_zoom of--hidden"
									onMouseMove={e => moveZoomedImageSlider(e)}
									onTouchMove={e => moveZoomedImageSlider(e)}>

									<picture onClick={() => {setIsZoomed(!isZoomed);}}
											 onMouseOut={() => setIsZoomed(false)}>
										<source
											srcSet={`${props.mark.image.url}?w=900&auto=format&q=75`}
											media="(min-width: 1000px)"
										/>
										<source
											srcSet={`${props.mark.image.url}?w=600&auto=format&q=75`}
											media="(min-width: 600px)"
										/>
										<img
											alt={props.mark.image.alt}
											src={`${props.mark.image.url}?w=400&auto=format`}
										/>
									</picture>
									<Transition
										in={isZoomed}
										mountOnEnter
										unmountOnExit
										appear
										timeout={{
											enter: 0,
											exit: TRANSITION_DURATION,
										}}>
										{state => (
											<picture
												className="product-hero__zoomed-image pen"
												style={{
													...DEFAULT_STYLE,
													...TRANSITION_STYLES[state],
													transformOrigin: `${zoomPosition[0]}% ${zoomPosition[1]}%`,
												}}>
												<img
													alt={props.mark.image.alt}
													src={`${props.mark.image.url}?w=1400&auto=format&q=75`}
												/>
											</picture>
										)}
									</Transition>
								</div>
								<div className="pa top right p2 pen">
									<button
										title="Close"
										aria-label="Close"
										onClick={() => setIsOpen(false)}
										className="db link--opacity pea">
										<img
											className="db"
											src="/images/icon-modal-close.svg"
											alt="Close"
										/>
									</button>
								</div>
							</div>
						</React.Fragment>
					)}
				</Transition>
			);

			return (
				<React.Fragment>
					{trigger}
					{modal}
				</React.Fragment>
			);
		},
		color: ({children, mark = {color: {hex: undefined}}}) => (
			<span style={{color: findAccessiblityColor(mark?.color?.hex)}}>{children}</span>
		),
	},
};
