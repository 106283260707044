import React from 'react';
import {connectToStore} from './Provider';
import CookiesBanner from './CookiesBanner';
import BundleReminder from './BundleReminder';
import {Transition} from 'react-transition-group';

const BUNDLE_ID = 'new';

const TRANSITION_DURATION = 200;

const RIBBON_DEFAULT_STYLE = {
	transition: `opacity ${TRANSITION_DURATION}ms ease-in-out`,
	opacity: 0,
};

const RIBBON_TRANSITION_STYLES = {
	entering: {opacity: 0},
	entered: {opacity: 1},
	exited: {opacity: 0},
	exiting: {opacity: 0},
};

const BottomRibbon = ({
	hasAcceptedCookies,
	cookiesDisclaimer,
	location,
	productOptions,
	freeproductOptions,
	bundleProductIds = [],
}) => {
	const showCookieDisclaimer =
		cookiesDisclaimer.enableDisclaimer && !hasAcceptedCookies;
	const showReminder =
		!!bundleProductIds.length &&
		location.pathname.indexOf('/cabinets') === -1;

	const cookieVisibilityDrawer = (
		<Transition
			in={showCookieDisclaimer}
			mountOnEnter
			unmountOnExit
			appear
			timeout={{
				enter: 0,
				exit: TRANSITION_DURATION,
			}}>
			{state => (
				<React.Fragment>
					<div
						style={{
							...RIBBON_DEFAULT_STYLE,
							...RIBBON_TRANSITION_STYLES[state],
						}}>
						<CookiesBanner cookiesDisclaimer={cookiesDisclaimer} />
					</div>
				</React.Fragment>
			)}
		</Transition>
	);
	const reminderVisibilityDrawer = (
		<Transition
			in={showReminder}
			mountOnEnter
			unmountOnExit
			appear
			timeout={{
				enter: 0,
				exit: TRANSITION_DURATION,
			}}>
			{state => (
				<React.Fragment>
					<div
						style={{
							...RIBBON_DEFAULT_STYLE,
							...RIBBON_TRANSITION_STYLES[state],
						}}>
						<BundleReminder productOptions={productOptions} />
					</div>
				</React.Fragment>
			)}
		</Transition>
	);

	return (
		<div className="pf bottom left right z10">
			{cookieVisibilityDrawer}
			{reminderVisibilityDrawer}
		</div>
	);
};

const mapStateToProps = ({savedBundlesById, hasAcceptedCookies}) => ({
	bundleProductIds: savedBundlesById[BUNDLE_ID].productIds,
	hasAcceptedCookies,
});

export default connectToStore(mapStateToProps)(BottomRibbon);
