import React, {Component} from 'react';
import classNames from 'classnames';
import {subscribe} from 'klaviyo-subscribe';
import {email as emailRegex} from 'magic-tricks/lib/regex';
import richText from '../serializers/richText';
import BlockContent from '@sanity/block-content-to-react';
import Radio from './Radio';

const INITIAL_STATE = {
	email: '',
	showInvalidEmailError: false,
	errorMessage: null,
	isSubmitting: false,
	isSuccess: false,
	successMessage: null,
	privacyAccepted: false,
};

export default class NewsletterFooter extends Component {
	constructor(props) {
		super();
		this.state = {...INITIAL_STATE};
	}

	onChangeEmail = e => {
		const {showInvalidEmailError} = this.state;

		this.setState({
			email: e.target.value,
			showInvalidEmailError: showInvalidEmailError
				? !emailRegex.test(e.target.value)
				: showInvalidEmailError,
		});
	};

	onTogglePrivacy = () => {
		this.setState({
			privacyAccepted: !this.state.privacyAccepted,
		});
	};

	handleSubmit = e => {
		e.preventDefault();

		const {email} = this.state;
		const klaviyoListId = process.env.GATSBY_KLAVIYO_LIST;

		if (!emailRegex.test(email)) {
			this.setState({
				showInvalidEmailError: true,
				isSubmitting: false,
			});

			return;
		}

		this.setState({
			isSubmitting: true,
			showInvalidEmailError: false,
		});

		subscribe(klaviyoListId, email)
			.then(response => {
				if (response.errors.length) {
					this.setState({
						isSubmitting: false,
						errorMessage: response.errors.join(' '),
					});
				} else {
					this.setState({
						isSubmitting: false,
						isSuccess: true,
					});
				}
			})
			.catch(error => {
				console.log(error);

				this.setState({
					isSubmitting: false,
					errorMessage: 'Uh oh, an error occured.',
				});
			});
	};

	render() {
		const {
			privacyAgreement,
			placeholder = 'Enter your email',
			cta = 'Sign Up',
			successMessage = 'Thanks for signing up!',
		} = this.props;

		const {
			email,
			isSubmitting,
			showInvalidEmailError,
			errorMessage,
			isSuccess,
			privacyAccepted,
		} = this.state;

		return (
			<div>
				<form
					title="Newsletter"
					aria-label="Newsletter"
					className="input--newsletter df fdc fdr--sm"
					onSubmit={this.handleSubmit}>
					<input
						type="email"
						onChange={this.onChangeEmail}
						className="input--primary db fg1 mr1--sm mb1 mb0--sm"
						value={email}
						placeholder={placeholder}
						aria-label={placeholder}
					/>
					<button
						title={cta}
						aria-label={cta}
						disabled={isSubmitting || !privacyAccepted}
						className={classNames(
							'button--secondary db ws--nowrap newsletter-button',
							{
								loading: isSubmitting,
							},
						)}
						name="signup"
						type="submit">
						{cta}
					</button>
				</form>

				{showInvalidEmailError && (
					<p className="mt1">Please enter a valid email.</p>
				)}

				{!showInvalidEmailError && errorMessage && (
					<p className="mt1">{errorMessage}</p>
				)}

				{isSuccess && <p className="mt1">{successMessage}</p>}

				<div className="mt1 mt2--lg">
					<div className="df fdr aic">
						<Radio
							onClick={this.onTogglePrivacy}
							className="fg0"
							active={privacyAccepted}
						/>
						<div
							onClick={this.onTogglePrivacy}
							className="fg1 rich-text sans--xs pl1">
							<BlockContent
								blocks={privacyAgreement}
								serializers={richText}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
