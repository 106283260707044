/*
Accessiblity Color fixes globally
*/
export default (hex) => {

	const ACCESSIBLITY_COLOR_FIXES = {
		'#8296DB':'#6271A4',
		'#FC6B70':'#BD5054',
		'#FD6B70':'#BD5054',
		'#87D98C':'#518254',
		'#87D98B':'#518254',
		'#59ABE5':'#3E78A0',
		'#FC9173':'#A45E4B',
		'#FFC75E':'#8C6D34',
	};

	let accessiblity_color=hex;

	if(accessiblity_color && accessiblity_color!= undefined && ACCESSIBLITY_COLOR_FIXES[accessiblity_color.toUpperCase()]){
		accessiblity_color=ACCESSIBLITY_COLOR_FIXES[accessiblity_color.toUpperCase()]
	}

	return accessiblity_color??'';
};

