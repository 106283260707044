import {getSubscriptions} from '../../api/account';

const hydrateCustomerSubscriptions = store => () => {
	const {customerToken, rechargeAccessToken} = store.getState();

	if (!customerToken || !rechargeAccessToken) return;

	store.setState({isFetchingSubscriptions: true});

	return getSubscriptions({rechargeAccessToken})
		.then(res => {
			if (!res.ok) {
				throw res;
			} else {
				return res.json();
			}
		})
		.then(({subscriptions}) => {
			store.setState({
				subscriptions,
				isFetchingSubscriptions: false,
			});
		})
		.catch(error => {
			store.setState({
				isFetchingSubscriptions: false,
			});
		});
};

export default hydrateCustomerSubscriptions;
